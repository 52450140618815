import AuthUtils from "../api/AuthUtils";
import { NotificationManager } from './common/react-notifications';
import { toast} from 'react-toastify'


export const doRequest = async (method, path, data, authorize) => {
    const body = data ? JSON.stringify(data) : null;
    let headers = {
        'Content-type': 'application/json'
    };
    if (typeof authorize === 'undefined' || authorize) {
        const token = await AuthUtils.getToken();
        headers['Authorization'] = 'Bearer ' + token
    }

    return fetch(process.env.REACT_APP_API_ENDPOINT + path, {
        method: method,
        mode: 'cors',
        headers: headers,
        body,
    })
        .then((res) => {
            return res.json();
        })
        .then(data => {
            if (!data) {
                return Promise.resolve({
                    success: false,
                    message: "Missing response data",
                });
            } if (data.success) {
                return Promise.resolve(data)
            } else {
                return Promise.resolve(data)
            }
        })
        .catch((err) => {
            console.log('get err', err);
            return Promise.resolve({
                success: false,
                message: err,
            });
        })
};

export const doRequestWithAlert = async (method, path, data, authorize) => {
    const response = await doRequest(method, path, data, authorize);
    if(response.success){
        return Promise.resolve(response);
    }else{
        showAlert('error', response.message || JSON.stringify(response));
        return Promise.resolve(response);
    }
}

export const doExternalRequest = async (method, path, data) => {
    const body = data ? JSON.stringify(data) : null;

    return fetch(path, {
        method: method,
        mode: 'cors',
        body,
    })
        .then((res) => {
            return res.text();
        })
        .then(data => {
            if (!data) {
                return Promise.resolve({
                    success: false,
                    message: "Missing response data",
                });
            } if (data.success) {
                return Promise.resolve(data)
            } else {
                return Promise.resolve(data)
            }
        })
        .catch((err) => {
            console.log('get err', err);
            return Promise.resolve({
                success: false,
                message: err,
            });
        })
};

export const getAllUrlParams = (url) => {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
};

export const alertDialog = (type,msg) =>{

    if(type === 'error'){
        NotificationManager.error(
            msg,
            'Error',
            10000,
            null,
            null,
            'filled'
        )
    }else{
        NotificationManager.primary(
            msg,
            'Success',
            10000,
            null,
            null,
            'filled'
        )
        if(type === 'info'){
            NotificationManager.info(msg);
        }

    }



}

export const showAlert = (type = "error", message) => {
    return toast(message, {
			autoClose: 3000,
			position: toast.POSITION.TOP_CENTER,
			closeOnClick: true,
			hideProgressBar: true,
			pauseOnFocusLoss: false,
			draggable: false,
			type:
				type === 'success'
					? toast.TYPE.SUCCESS
					: type === 'error'
					? toast.TYPE.ERROR
					: toast.TYPE.INFO,
		})
}

export const doImpersonate = async (clientId, cb = ()=>{}) => {
        const body = {
            client_id: clientId
        }
        try {
            const resp = await doRequest('POST', '/admin_impersonate_client', body);
            if(resp.success){
                AuthUtils.refreshToken({
                    callback: async () => {
                        try {
                            const response = await doRequest('GET', '/get_client_data', null);
                            setSessionStorage(response.data.email, response.data.client_name, response.data.client_id, response.data.user_email);
                            showAlert('success', 'Account successfully impersonated');
                            cb();
                        } catch (err) {
                            showAlert('error', err.message || 'Some error occurred. Try again later.');
                            cb();
                        }
                    }
                });
            }else{
                throw resp;
            }

        } catch (err) {
            cb();
            showAlert('error', err.message || 'Some error occurred. Try again later.');
        }

    }

export const setSessionStorage = (email, company, clientId, userEmail) => {
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('companyName', company);
        sessionStorage.setItem('clientId', clientId);
        sessionStorage.setItem('userEmail', userEmail);
        window.dispatchEvent(new Event('storage'))
}

export const getWhetherServiceRunning = (client_id, clusters, service) => {
  if(clusters.length !== 0){
    if(service === 'metabase'){
      let flag = false;
      clusters.forEach(identifier => {
        const text = identifier.match(/(?<=mbc-).*/);
        if(text && text.length !== 0){
            if(text[0] === client_id){
                flag = true;
            };
        }
      });
      return flag;
    }
    if(service === 'dwh'){
      let flag = false;
      clusters.forEach(cluster => {
        const text = cluster.DBInstanceIdentifier.match(/(?<=metadb-).*/);
        if(text && text.length !== 0){
          if(text[0] === client_id){
              flag = true;
          };
        }
      });
      return flag;
    }
    if(service === 'etl_dev'){
      let flag = false;
      clusters.forEach(identifier => {
        const text = identifier.match(/(?<=airflow-cluster-dev-).*/);
        if(text && text.length !== 0){
          if(client_id.startsWith(text[0])){
              flag = true;
          }
        }
      });
      return flag;
    }
    if(service === 'etl_prod'){
      let flag = false;
      clusters.forEach(identifier => {
        const text = identifier.match(/(?<=airflow-cluster-).*/);
        if(text && text.length !== 0){
          if(!text[0].startsWith('dev-')){
            if(text[0] === client_id){
                flag = true;
            }
          }
        }
      });
      return flag;
    }
  }
  return false;
}

export const isImpersonating = (client_id)=>{
    const impersonateId = sessionStorage.getItem('clientId');
    if(client_id === impersonateId){
        return true;
    }
    return false;
}
