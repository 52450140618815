import React from 'react';
import {Admin, Resource} from 'react-admin';
import Dashboard from './components/Dashboard';
import MyLayout from './components/Layout';
import AuthUtils from './api/AuthUtils';
import authProvider from './api/authProvider';
import {createDataProvider} from './api/data-provider';
import customRoutes from './customRoutes';
import LoadingIndicator from './components/LoadingIndicator';
import MyLoginPage from './components/MyLoginPage';
import AlertContainer from './components/Alert/AlertContainer.component';
import './App.css';
import './assets/fonts/iconsmind-s/css/iconsminds.css';

const App = () => {

    return (
        <AuthUtils.RequireAuth loadingIndicator={<LoadingIndicator/>}>
            <Admin dataProvider={createDataProvider()} authProvider={authProvider} dashboard={Dashboard}
                   appLayout={MyLayout} customRoutes={customRoutes} loginPage={MyLoginPage}>
                <Resource name="facebook-tokens-general" />
            </Admin>
            <AlertContainer />
        </AuthUtils.RequireAuth>
    );
};

// export default createStore(App);
export default App;
