import React from 'react'

const Details = ({
    accountDetails
}) => {
    return (
        <div style={{width: '100%', display:'flex', justifyContent:'space-between', color: '#000'}}>
            {accountDetails.userEmail && <div>{`${accountDetails.userEmail}`}</div>}
            {accountDetails.email && <div>{`Impersonate: ${accountDetails.email}`}</div>}
        </div>
    )
}

export default Details
