import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { getResources, translate } from 'ra-core';
import MyDashboardMenuItem from './MyDashboardMenuItem';
import MyMenuItemLink from './MyMenuItemLink';

import Storage from '@material-ui/icons/Storage';
import Equalizer from '@material-ui/icons/Equalizer';
import Sync from '@material-ui/icons/Sync';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Face from '@material-ui/icons/Face';

import MenuClass from '../styles/menu.module.css';


const styles = createStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        active : { fontWeight: 'bold' , color : 'green' },
    },
});



export class Menu extends React.Component {
    state = {
        openIntegrations: false,
        openOthers: false,
        openDataScience: false,
        openTracking: false,
        openHelp: false,
        integrations: [],
        reportingEndpointUrl: '',
        anomalyEndpointUrl: '',
        beaconEndpointUrl: '',
        tmtEndpointUrl: '',
        airflowEndpointUrl: '',
        clientId: false,
    };

    render() {
        const {classes,
            className,
            dense,
            hasDashboard,
            onMenuClick,
            open,
            pathname,
            resources,
            translate,
            logout,
            ...rest} = this.props;

        return (
            <div className={classnames(classes.main, className , MenuClass['main-menu'])} {...rest}>
                {hasDashboard && <MyDashboardMenuItem onClick={onMenuClick} />}
                <MyMenuItemLink
                    to="/raw-api-data"
                    primaryText="Raw API Data"
                    leftIcon={<Storage />}
                />
                <MyMenuItemLink
                    to="/last-runs"
                    primaryText="Last Runs Statuses"
                    leftIcon={<Equalizer />}
                />
                <MyMenuItemLink
                    to="/retrigger-loading"
                    primaryText="Retrigger Loading Service"
                    leftIcon={<Sync />}
                />
                <MyMenuItemLink
                    to="/client-secrets-parameters"
                    primaryText="Secrets and Parameters"
                    leftIcon={<Fingerprint />}
                />
                <MyMenuItemLink
                    to="/clients"
                    primaryText="Clients"
                    leftIcon={<Face />}
                />
            </div>
        )
    }
}

Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    pathname: PropTypes.string,
    resources: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    resources: getResources(state),
    pathname: state.router.location.pathname, // used to force redraw on navigation
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {}, // Avoid connect passing dispatch in props,
        null,
        {
            areStatePropsEqual: (prev, next) =>
                prev.resources.every(
                    (value, index) => value === next.resources[index] // shallow compare resources
                ) &&
                prev.pathname === next.pathname &&
                prev.open === next.open,
        }
    ),
    withStyles(styles)
);

// export default enhance(withStore(Menu));
// export default enhance(reactn(Menu));
export default enhance(Menu);
