import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

const TokensGeneral = {
    idColumn: "created",
    path: "/tokens",
    columns: {
        "created": "date",
        "access_token": "string",
        "service": "string",
        "expires": "date",
        "fb_account_id": "string"
    }
};

const TokensAds = {
    idColumn: "created",
    path: "/tokens",
    columns: {
        "created": "date",
        "access_token": "string",
        "service": "string",
        "expires": "date",
        "fb_account_id": "string"
    }
};

const TokensInstagram = {
    idColumn: "created",
    path: "/tokens",
    columns: {
        "created": "date",
        "access_token": "string",
        "service": "string",
        "expires": "date",
        "fb_account_id": "string"
    }
};

// const EventDef = {
//     idColumn: "name",
//     path: "/event-defs",
//     columns: {
//         "eventType": "string",
//         "name": "string",
//         "startDate": "date",
//         "endDate": "date",
//         "published": "boolean",
//         "description": "string",
//         "amount": "int",
//         "appliedRules": "array",
//         "active": "boolean",
//         "deviantAmount": "int",
//         "deviantOn": "date",
//         "deviantOff": "date",
//     }
// };


const getType = resource => {
    switch (resource) {
        case "facebook-tokens-general":
            return TokensGeneral;
        case "facebook-tokens-ads":
            return TokensAds;
        case "facebook-tokens-instagram":
            return TokensInstagram;
        default:
            throw new Error(`Unmapped resource: ${resource}`);
    }
}

const mapEntity = (type, resource, entity = {}, typeRequest = null) => {
    let res = {
        id: entity[type.idColumn],
        ...entity,
    }
    for (const columnName in type.columns) {
        try {
            switch (type.columns[columnName]) {
                case "date":
                    if (res[columnName]) {
                        let date = res[columnName];
                        // if (typeof date === "string") {
                        //     date = parseInt(date);
                        // }
                        if (typeRequest === 'GET_ONE') {
                            res[columnName] = date;
                        } else {
                            let dateobj = new Date(date);
                            // if `date` was not successfully parsed as date, display it as it is
                            if (isNaN(dateobj)) {
                                res[columnName] = date;
                            } else {
                                res[columnName] = moment(dateobj).format('DD.MM.YYYY HH:mm:ss') + ' UTC';
                            }
                        }
                    }
                    break;
                case "int":
                    if (res[columnName] !== '') {
                        res[columnName] = parseInt(res[columnName]) || 0;
                    }
                    break;
                case "float":
                    res[columnName] = parseFloat(res[columnName]);
                    break;
                case "string":
                    res[columnName] = res[columnName].toString();
                    break;
                case "array":
                    res[columnName] = Array.isArray(res[columnName]) ? res[columnName] : [];
                    break;
                case "boolean":
                    res[columnName] = res[columnName] === 'true' ? true : (res[columnName] === 'false' ? false : res[columnName]);
                    break;
                default:
                    break;
            }
        } catch (e) {
            // type can not be parsed
        }
    }
    return res;
}

export const getResourcePath = (resource) => getType(resource).path || `/${resource}`;

export const mapResource = (resource, entity, typeRequest = null) => {
    return mapEntity(getType(resource), resource, entity, typeRequest)
}

export const mapResourceArray = (resource, entities) => {
    const t = getType(resource);
    return entities.map(entity => mapEntity(t, resource, entity));
}
