import React from 'react'
import classnames from 'classnames';
import appbarStyle from '../../styles/appbar.module.css';

const ImpersonatedDetails = ({accountDetails}) => {
    return (
        <div>
             <ul className={classnames(appbarStyle['menu-wrapper'])}>
                    {
                        accountDetails.companyName &&
                        <li><strong>Company: </strong>{accountDetails.companyName}</li>
                    }
                    {
                        accountDetails.clientId &&
                        <li><strong>Client ID: </strong>{accountDetails.clientId}</li>
                    }
                </ul>
        </div>
    )
}

export default ImpersonatedDetails
