import React from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import moment from 'moment-timezone';

import CardHeader from './CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import {doRequest} from "./helpers";

import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';
import tableStyle from '../styles/table.module.css';

moment.tz.setDefault('UTC');

const styles = {
    root: {
        marginBottom: 16
    },
    content: {
        backgroundColor: '#43a047',
    }
};

class HomeRawApiData extends React.Component {
    constructor(props) {
        super(props);

        var date_start = new Date();
        date_start.setDate(date_start.getDate() - 7);
        date_start = moment(date_start).format('YYYY-MM-DD');
        var date_end = new Date();
        date_end = moment(date_end).format('YYYY-MM-DD');

        this.state = {
            clientId: '',
            serviceId: '',
            clients: [],
            clientsMap: {},
            services: [],
            servicesMap: {},
            result: [],
            loadingClients: false,
            loadingServices: false,
            loadingResult: false,
            lastEvaluatedKey: '',
            dateStart: date_start,
            dateEnd: date_end,
            error: null,
        };

        // this.submitQuery = this.submitQuery.bind(this);
    }

    componentDidMount() {
        // this.getClientOptions();
        this.getServiceOptions();
    }

    async getClientOptions() {
        this.setState({loadingClients: true});
        let res = await doRequest('GET', '/admin_list_clients', null)
        this.setState({loadingClients: false});

        if (res.success) {
            let clientsMap = {};
            for (var i in res.data) {
                clientsMap[res.data[i]['client_id']] = res.data[i]['client_name'];
            }
            this.setState({ clients: res.data, clientsMap: clientsMap, error: null });
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    async getServiceOptions() {
        this.setState({loadingServices: true});
        let res = await doRequest('GET', '/admin_list_services', null)
        this.setState({loadingServices: false});

        if (res.success) {
            let servicesMap = {};
            for (var i in res.data) {
                servicesMap[res.data[i]['service_id']] = res.data[i]['service_name'];
            }
            this.setState({ services: res.data, servicesMap: servicesMap, error: null });
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    async submitQuery(replace_results) {
        if (typeof replace_results === 'undefined') {
            replace_results = false;
        }
        var exclusive_start_key = this.state.lastEvaluatedKey;
        if (replace_results) {
            exclusive_start_key = '';
            this.setState({lastEvaluatedKey: ''});
        }

        this.setState({loadingResult: true});
        let res = await doRequest('POST', '/admin_query_raw_api_data', {
            client_id: this.state.clientId,
            service_id: this.state.serviceId,
            date_start: this.state.dateStart,
            date_end: this.state.dateEnd,
            exclusive_start_key: exclusive_start_key
        });
        this.setState({loadingResult: false});

        if (res.success) {
            if (res.data.length === 0) {
                // this.setState({ lastEvaluatedKey: res.last_evaluated_key});
                alert('No data');
                return;
            }

            let data = this.state.result;
            if (replace_results) {
                data = [];
            }
            for (var i in res.data) {
                var item = res.data[i];
                item['client_name'] = this.state.clientsMap[item['client_id']];
                item['service_name'] = this.state.servicesMap[item['service_id']];
                item['filename_expanded'] = '';
                data.push(item)
            }
            this.setState({ result: data, error: null , lastEvaluatedKey: res.last_evaluated_key});
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    toggleExpandKey = (idx) => {
        var result = this.state.result;
        if (result[idx].filename_expanded.length === 0) {
            result[idx].filename_expanded = result[idx].filename;
        } else {
            result[idx].filename_expanded = '';
        }
        this.setState({result: result});
    };

    render() {
        let { error } = this.state;

        return (
            <Paper style={{ padding: 5 }} className={commonStyle['default']}>
                <div className={rootStyle['container-fluid']}>
                    <div className={rootStyle.card}>
                        <CardHeader
                            title='Query Raw API Data'
                            description=""
                        />
                        <div className={rootStyle['card-body']}>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="client-select" className={tableStyle['form-left-label']}>Client ID:</label>
                                    {/* <select
                                        className={rootStyle["custom-select"]}
                                        onChange={(e) => {
                                            this.setState({ clientId: e.target.value });
                                        }}
                                        id="client-select"
                                        value={this.state.clientId}>
                                        <option value=''>Select Client</option>
                                        {this.state.clients && this.state.clients.length > 0 ?
                                            this.state.clients.map((o, i) => {
                                                return (
                                                    <option key={i} value={o['client_id']}>{o['client_name']}</option>
                                                );
                                            })
                                            : null}
                                    </select> */}
                                    <input type="text"
                                           className={`${rootStyle["form-control"]}`}
                                           value={this.state.clientId}
                                           onChange={(e) => {
                                               this.setState({ clientId: e.target.value });
                                           }}
                                    />
                                </div>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="service-select" className={tableStyle['form-left-label']}>Or service:</label>
                                    <select
                                        className={rootStyle["custom-select"]}
                                        onChange={(e) => this.setState({ serviceId: e.target.value })}
                                        id="service-select"
                                        value={this.state.serviceID}>
                                        <option value=''>Select Service</option>
                                        {this.state.services && this.state.services.length > 0 ?
                                            this.state.services.map((o, i) => {
                                                return (
                                                    <option key={i} value={o['service_id']}>{o['service_name']}</option>
                                                );
                                            })
                                            : null}
                                    </select>
                                </div>
                            </div>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="date-start-input" className={tableStyle['form-left-label']}>Time Start:</label>
                                    <input type="date"
                                           className={`${rootStyle["form-control"]}`}
                                           value={this.state.dateStart}
                                           onChange={(e) => {
                                               this.setState({ dateStart: e.target.value });
                                           }}
                                           />
                                </div>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="date-end-input" className={tableStyle['form-left-label']}>Time End:</label>
                                    <input type="date"
                                           className={`${rootStyle["form-control"]}`}
                                           value={this.state.dateEnd}
                                           onChange={(e) => {
                                               this.setState({ dateEnd: e.target.value });
                                           }}
                                    />
                                </div>
                            </div>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label className={tableStyle['form-left-label']}>&nbsp;</label>
                                    <button
                                        type="submit"
                                        className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'])}
                                        onClick={() => {this.submitQuery('replace')}}
                                    >
                                        {this.state.loadingResult && <CircularProgress style={{ position: 'absolute' }} size={25} thickness={2} />}
                                        Submit
                                    </button>
                                </div>
                            </div>

                            {error &&
                            <div className={tableStyle['error']} style={{ marginBottom: 16, marginTop: 16 }}>{error}</div>
                            }

                            <div className={rootStyle['row']}>
                                <div className={tableStyle['table-wrapper']}>
                                {this.state.result && this.state.result.length > 0 && <React.Fragment>
                                    <span>Note: if you want to download files, make sure you are logged in AWS dashboard with account having access to them</span>
                                    <table>
                                    <thead className={`${tableStyle["table-head"]}`}>
                                        <tr>
                                            <th>Client</th>
                                            <th>Service</th>
                                            <th>Download</th>
                                            <th>Bucket</th>
                                            <th>Key</th>
                                            <th>Request Id</th>
                                            <th>Storage class</th>
                                            <th>Run TS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.result.map((item, idx) => {
                                        //TODO: S3 regions should not be hardcoded to 'eu-central-1
                                        var run_ts = new Date(item['run_ts']);
                                        if (isNaN(run_ts)) {
                                            run_ts = '-';
                                        } else {
                                            run_ts = moment(run_ts).format('DD.MM.YYYY HH:mm:ss') + ' UTC';
                                        }

                                        return (
                                            <tr key={idx}>
                                                <td>{item['client_name']}</td>
                                                <td>{item['service_name']}</td>
                                                <td>
                                                    {item['storage_class'] === 'STANDARD' ?
                                                        <a target="_blank"
                                                           rel="noopener noreferrer"
                                                           href={'https://' + item['bucket'] + '.s3.eu-central-1.amazonaws.com/' + item['filename']}
                                                           title={item['filename']}
                                                        >Download</a>
                                                        :
                                                        <span>(No direct download for files in Glacier)</span>
                                                    }
                                                </td>
                                                <td>{item['bucket']}</td>
                                                <td>
                                                    <button
                                                        type="submit"
                                                        className={classnames(rootStyle['btn'], rootStyle['btn-primary'])}
                                                        onClick={() => {this.toggleExpandKey(idx)}}
                                                    >Click to expand
                                                    </button> <br />
                                                    {item['filename_expanded']}
                                                    </td>
                                                <td>{item['request_id']}</td>
                                                <td>{item['storage_class']}</td>
                                                <td>{run_ts}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                    {this.state.lastEvaluatedKey && <button
                                        type="submit"
                                        className={classnames(rootStyle['btn'], rootStyle['btn-primary'])}
                                        onClick={() => {this.submitQuery()}}
                                        >Load More
                                    </button>}
                                </React.Fragment>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(HomeRawApiData)