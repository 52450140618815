import React from 'react';
import ClientOAuth2 from 'client-oauth2';
import jwtDecode from 'jwt-decode';
import MyLoginPage from '../components/MyLoginPage';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

var redirectUri = window.location.origin + '?authenticate';
if (redirectUri.indexOf('localhost') === -1) {
    redirectUri = redirectUri.replace('http:', 'https:');
}

const cognitoOauth2 = new ClientOAuth2({
	clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
	accessTokenUri: process.env.REACT_APP_COGNITO_CLIENT_ENDPOINT + '/token',
	authorizationUri: process.env.REACT_APP_COGNITO_CLIENT_ENDPOINT + '/login',
	redirectUri: redirectUri,
	scopes: ['openid', 'profile', 'email', 'key2market/default', 'aws.cognito.signin.user.admin']
	// Note: aws.cognito.signin.user.admin is required if the user should be able to change any properties on his own account
	// like change his password
})

function redirectOauth() {
	// window.location = cognitoOauth2.code.getUri()
	console.log('redirectOauth')
}

function redirectHome() {
	window.location = '/'
}

function saveToken(token) {
	sessionStorage.token_data = JSON.stringify({
		data: token.data,
		expires_at: token.expires.getTime()
	})
}

function saveRefreshedToken(token) {
	const prevData = JSON.parse(sessionStorage.token_data);
	sessionStorage.token_data = JSON.stringify({
		data: {
			...prevData.data,
			access_token: token.data.access_token,
			id_token: token.data.id_token,
		},
		expires_at: token.expires.getTime()
	})
}

function loadToken() {
	const data = JSON.parse(sessionStorage.token_data)
	const token = new ClientOAuth2.Token(cognitoOauth2, {
		...data.data,
		expires_in: Math.floor((data.expires_at - new Date().getTime()) / 1000)
	})
	return token
}

function getCognitoPool(){
	const poolData = {
		UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
		ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
	};
	var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
	return userPool;
}

function getCurrentUser(){
	const userPool = getCognitoPool();
	const cognitoUser = userPool.getCurrentUser();
	return cognitoUser;
}

function refreshToken(options) {
	if (typeof options === 'undefined') {
		options = {};
	}
    if (typeof options.redirect_home === 'undefined') {
        options.redirect_home = false;
    }
    if (typeof options.callback === 'undefined') {
        options.callback = false;
    }
	const cognitoUser = getCurrentUser();

	if (!cognitoUser)
	{
        localStorage.removeItem('token_data');
        window.location.reload();
        return
	}

	try{
		cognitoUser.getSession((err, data)=>{
			if(!err){
				cognitoUser.refreshSession(data.refreshToken, (err, res)=>{
					if(!err){
						const session = res;
						const date = new Date(0);
						const expires = new Date(date.setUTCSeconds(session.accessToken.payload.exp));
						const newToken = {
							data: {
								access_token: session.getAccessToken().getJwtToken(),
								id_token: session.getIdToken().getJwtToken(),
							},
							expires,
						};
						saveRefreshedToken(newToken);

						if (options.redirect_home) {
							redirectHome();
						}
						if (options.callback) {
							options.callback(newToken);
						}
					}else{
						throw err;
					}
				})
			}else{
				throw err;
			}
		})
	}catch(e){
		console.error(e);
		redirectOauth();
	}
}

function requireToken() {
	return new Promise((resolve, reject) => {
		// Redirected from AWS
		if (window.location.search.indexOf('?authenticate') === 0) {
			// Get token from URL
			cognitoOauth2.code.getToken(window.location.href)
			.then(function (token) {
				// Save the token data in the session storage
				// It will be automatically deleted on browser close
				saveToken(token)
				redirectHome()
			})
		} else if (sessionStorage.token_data) {
			const token = loadToken();
			if (token.expired()) {
				console.log('refreshing token')
				refreshToken({callback: function(token){
					resolve(token);
					}});
			} else {
				resolve(token)
			}
		} else {
			redirectOauth()
		}
	})
}

function getUserAttributes() {
	return requireToken().then(token => {
		var attr = jwtDecode(token.data.id_token);

		// js tracking needs this for user_id
		window.cognito_user_id = attr['cognito:username'];
		// // setting up localStorage for
		// var keyPrefix = 'CognitoIdentityServiceProvider.' + process.env.REACT_APP_COGNITO_CLIENT_ID + '.' + attr['cognito:username']+'.';
        // localStorage.setItem(keyPrefix+'accessToken', token.data.access_token);
        // localStorage.setItem(keyPrefix+'refreshToken', token.data.refresh_token);
        // localStorage.setItem(keyPrefix+'idToken', token);
		return attr;
	})
}

function getToken(request) {
	return requireToken().then(token => {
		return token.data.id_token
	})
}

function logout() {
	delete sessionStorage.token_data;
	const cognitoUser = getCurrentUser();
	if(cognitoUser){
		cognitoUser.signOut();
	}
	const logoutURL = cognitoOauth2.code.getUri().replace("/login", "/logout") + '&logout_uri=' + window.location.origin;
	window.location = logoutURL;
}

class RequireAuth extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            authorized: false,
        }
    }

    componentWillMount() {
        getToken().then(() => {
            this.setState({
                authorized: true,
            })
        })
    }

    render() {
        if (this.state.authorized) {
            return this.props.children;
        } else {
            // return this.props.loadingIndicator || "Please wait...";
			return <MyLoginPage/>
        }
    }

}

export default {
    RequireAuth,
	getUserAttributes,
	getToken,
	logout,
	refreshToken,
	saveToken,
    redirectHome
}
