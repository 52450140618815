import React from 'react'
import rootStyle from "../../styles/style.module.css";

const Toggle = ({
    isDisabled,
    isChecked,
    onChange,
}) => {
    return (
         <div>
                  <label
                      className={`${rootStyle["switch"]} ${rootStyle["switch-pill"]} ${rootStyle["switch-primary"]}`}
                      style={{
                          margin: "0 10px -10px 0",
                          color: "#5a9120",
                          cursor: "pointer",
                      }}
                  >
                      <input
                          type="checkbox"
                          className={
                              `${rootStyle["switch-input"]}`
                          }
                          disabled={isDisabled}
                          checked={isChecked}
                          onChange={onChange}
                      />
                      <span
                          className={
                              rootStyle["switch-slider"]
                          }
                      ></span>
                  </label>
              </div>
    )
}

export default Toggle
