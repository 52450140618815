import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import {TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import useTable from '../Table/useTable';
import Toggle from '../Toggle/Toggle';
import rootStyle from "../../styles/style.module.css";
import tableStyle from '../../styles/table.module.css';
import {doImpersonate} from '../helpers';
import CircularProgress from '@material-ui/core/CircularProgress';

const MyTable = ({misc_attributes, handleOnChange})=>{
  const headCells = [
    {id: 'service', label: 'Service',  disableSorting: true},
    {id: 'allow', label: 'Allow', disableSorting: true},
  ];

  const records = [
    {id: 'dwh', service: 'DWH'},
    {id: 'etl', service: 'ETL'},
    {id: 'sso', service: 'SSO'},
    {id: 'reports', service: 'Reports'},
    {id: 'tableau_reports', service: 'Tableau'},
  ]
  const {TblContainer, TblHead} = useTable(null, headCells);
  const [isChecked, setIsChecked] = useState({
    dwh: false,
    etl: false,
    sso: false,
    reports: false,
    tableau_reports: false,
  });
  const handleChange = (e, id)=>{
    setIsChecked((prev)=>{
      handleOnChange({
        ...prev,
        [`${id}`]: !prev[id],
      });
      return {
        ...prev,
        [`${id}`]: !prev[id],
      }
    });
    ;
  }

  useEffect(() => {
    if(misc_attributes){
      setIsChecked({
        dwh: misc_attributes['allow.dwh'],
        etl: misc_attributes['allow.etl'],
        sso: misc_attributes['allow.sso'],
        reports: misc_attributes['allow.reporting'],
        tableau_reports: misc_attributes['provision.tableau_reports'],
      })
    }
    return () => {}
  }, [misc_attributes]);

  return <>
 <TblContainer>
      <TblHead />
      <TableBody>
        {
          records.map((record)=>(
            <TableRow key={record.id}>
              <TableCell>{record.service}</TableCell>
              <TableCell>
                  <Toggle onChange={(e)=>{handleChange(e, record.id)}} isChecked={isChecked[record.id]} />
              </TableCell>
          </TableRow>
          ))
        }
      </TableBody>
  </TblContainer>
  </>

}

const ClientDetails = ({
    clientDetails,
    handleOnChange,
    handlePayeeChange,
}) => {
    const {
        clientId,
        clientName,
        companyName,
        email,
        lastLoginAt,
        createdDate,
        misc_attributes,
        process_integrations,
    } = clientDetails;
    const [impersonateDisabled, setImpersonateDisabled] =  useState(false);
    const [processIntegration, setProcessIngerations] = useState(process_integrations === '1'?true:false);
    useEffect(
      ()=>{
        setProcessIngerations(process_integrations === '1'?true:false)
      }
    ,[process_integrations]);

    const localHandlePayeeChange = (e)=>{
      setProcessIngerations((prev)=>{
        handlePayeeChange(!prev);
        return !prev;
      })
    }
    return (
        <>
                         {/* Details */}
             <Typography variant="headline" style={{marginTop: '20px'}} gutterBottom={true} headlineMapping={{display1: 'h1'}}>Client details</Typography>
             <>
              <div className={rootStyle['row']} style={{ padding: 10 }}>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`} >
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Impersonate account</label><br></br>
                      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                          <button onClick={() => {
                              setImpersonateDisabled(true);
                              doImpersonate(clientId, ()=>{
                                setImpersonateDisabled(false);
                              })
                          }}
                              className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'], !impersonateDisabled ? rootStyle['btn-primary'] : null)}
                              disabled={impersonateDisabled}>
                              Impersonate
                          </button>
                          {impersonateDisabled && <CircularProgress size={25} thickness={2} />}
                      </div>
                  </div>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`} >
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Paying user</label><br></br>
                      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px' }}>
                          <Toggle isChecked={processIntegration} onChange={localHandlePayeeChange}/>
                      </div>
                  </div>
              </div>
              <div className={rootStyle['row']} style={{ padding: 10 }}>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`} >
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Client Id</label><br></br>
                      <label>{clientId}</label>
                  </div>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`}>
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Client Name</label><br></br>
                      <label>{clientName}</label>

                  </div>
              </div>
              <div className={rootStyle['row']} style={{ padding: 10 }}>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`} >
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Company Name</label><br></br>
                      <label>{companyName}</label>
                  </div>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`}>
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Email</label><br></br>
                      <label>{email}</label>

                  </div>
              </div>
              <div className={rootStyle['row']} style={{ padding: 10 }}>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`} >
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>User Last Login</label><br></br>
                      <label>{lastLoginAt}</label>
                  </div>
                  <div style={{ margin: 0, borderBottom: '1px solid #f3f3f3' }} className={`${rootStyle['form-group']} ${rootStyle['col-sm-6']}`}>
                      <label htmlFor="client-select" className={tableStyle['form-left-label']}>Created Date</label><br></br>
                      <label>{createdDate}</label>
                  </div>
              </div>
              </>
              {/* Details */}
              <Typography variant="headline" style={{marginTop: '20px'}} gutterBottom={true} headlineMapping={{display1: 'h1'}}>Services</Typography>
              <div style={{width: '50%'}}>
                <MyTable  misc_attributes={misc_attributes} handleOnChange={handleOnChange}/>
              </div>
        </>
    )
}

export default ClientDetails
