import React from 'react';
import PropTypes from 'prop-types';

// const CustomDateField = ({ source, record = {} }) => <span>{record[source]}</span>;
import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';

class CardHeader extends React.Component {
    render() {
        const title = this.props.title;
        const description = this.props.description;

        return (
            <div className={rootStyle['card-header']}>
                <div className={commonStyle['headText']}>{title}</div>
                {description}
            </div>
        );
    }
}

CardHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

export default CardHeader;
