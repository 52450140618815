import React, {Component} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import ForgottenPasswordPopup from './ForgottenPasswordPopup';
import ResetPasswordPopup from './ResetPasswordPopup';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';
import AuthUtils from'../api/AuthUtils';
import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';
import IconSVGQuestionMark from './common/IconSVGQuestionMark/IconSVGQuestionMarks';
import {CustomInput} from 'reactstrap';
import { showAlert } from './helpers';


class MyLoginPage extends Component {
    cognitoUser = {}
    state = {
        register_opened: false,
        register_code_opened : false,
        forgotten_password_opened: false,
        reset_password_opened : false,
        enter_mfa_code_opened: false,
        remember_device: false,
        submitLoading: false,
        userCode: '',
        email: '',
        password: '',
        error_message: '',
        is_loading: false,
        cognito_user_name  :'',
        code_delivery_details : ''
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    submit = (e) => {
        var me = this;
        e.preventDefault();

        if (this.state.email.length === 0 || this.state.password.length === 0) {
            this.setState({error_message: "All fields are required"});
            return;
        }

        me.setState({is_loading: true});
        var authenticationData = {
            Username : this.state.email,
            Password : this.state.password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
        const poolData = {
            UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
        };
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        var userData = {
            Username : this.state.email,
            Pool : userPool
        };
        this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        this.cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: this.onLoginSuccess,
            onFailure: this.onLoginFailure,
            totpRequired: function(){
                me.setState({
                    enter_mfa_code_opened: true,
                })
            }
        });
    };

    submitUserCode = (e) => {
        e.preventDefault();
        this.setState({submitLoading: true})
        this.cognitoUser.sendMFACode(this.state.userCode, {
            onSuccess: (e, userConfirmationNecessary) => {
                this.setState({submitLoading: false}, () => {
                    this.onLoginSuccess(e, userConfirmationNecessary)
                })
            },
            onFailure: (e) => {
                this.setState({submitLoading: false}, () => {
                    this.onLoginFailure(e);
                });
            },
        }, 'SOFTWARE_TOKEN_MFA');
    }

    onLoginSuccess = (result, userConfirmationNecessary) => {
        this.setState({is_loading: false});
        var accessToken = result.getAccessToken().getJwtToken();
        var decoded = jwtDecode(accessToken);

        // check if the user is member of the 'admin' group
        if (typeof decoded['cognito:groups'] === 'undefined' || decoded['cognito:groups'].indexOf('admin') === -1) {
            alert('This user does not have access to the admin app');
            return;
        }

        try{
            if(this.state.remember_device){
                this.cognitoUser.setDeviceStatusRemembered({
                    onSuccess: (success) => {
                        AuthUtils.refreshToken({redirect_home: true});
                    },
                    onFailure: (err) => {
                        showAlert('error', 'Device could not be remembered');
                        AuthUtils.refreshToken({redirect_home: true});
                    }
                })
            }else{
                AuthUtils.refreshToken({redirect_home: true});
            }
        }catch(e){}

        AuthUtils.saveToken({
            accessToken: accessToken,
            refreshToken: result.refreshToken.token,
            idToken: result.idToken.jwtToken,
            tokenType: 'Bearer',
            expires: new Date(decoded.exp),
            data: {
                access_token: accessToken,
                refresh_token: result.refreshToken.token,
                id_token: result.idToken.jwtToken,
                token_type: 'Bearer',
                expires_in: 3600
            }
        });
    }

    onLoginFailure = (err) => {
        this.setState({is_loading: false});
        if (err.message) {
            this.setState({error_message: err.message});
        } else {
            this.setState({error_message: 'Incorrect username or password'});
        }
    }

    onClickForgottenPassword = (e) => {
        e.preventDefault();
        console.log('to forgotten password')
        this.setState({forgotten_password_opened: true})
    };

    set_cognito_user_name = (cognito_user_name) => {
        //e.preventDefault();
        console.log('cognito user name : ' , cognito_user_name);
        if(cognito_user_name !== undefined){
            this.setState({
                cognito_user_name : cognito_user_name
            })
        }
        this.setState({
            register_opened: false,
            register_code_opened : true
        })
    };

    set_cognito_user_name_details = (cognito_user_name , code_delivery_details) => {
        //e.preventDefault();
        console.log('cognito user name : ' , cognito_user_name);
        if(cognito_user_name !== undefined){
            this.setState({
                cognito_user_name : cognito_user_name,
                code_delivery_details : code_delivery_details
            })
        }
        this.setState({
            forgotten_password_opened: false,
            reset_password_opened : true
        })
    };

    closeForgottenPassword = (e) => {
        e.preventDefault();
        this.setState({forgotten_password_opened: false})
    };

    closeResetPassword = (e) => {
        e.preventDefault();
        this.setState({reset_password_opened: false})
    };

    closeAllModal = (e) => {
        this.setState({
            register_opened: false,
            forgotten_password_opened : false,
            register_code_opened : false,
            reset_password_opened : false
        })
    };

    open_code = (e) => {
        this.setState({
            register_code_opened: true
        })
    };

    render() {

        return (
            <React.Fragment>
                <ForgottenPasswordPopup
                    open={this.state.forgotten_password_opened}
                    set_cognito_user_name_details={this.set_cognito_user_name_details}
                    close={this.closeForgottenPassword}
                    closeAll={this.closeAllModal} />


                <ResetPasswordPopup
                    open={this.state.reset_password_opened}
                    cognito_user={this.state.cognito_user_name}
                    code_delivery_details={this.state.code_delivery_details}
                    close={this.closeResetPassword}
                    closeAll={this.closeAllModal} />

                <Paper >
                    <div className={commonStyle['center-login']}>
                        <div className={`${rootStyle['container']}`}>
                            <div className={`${rootStyle['row']} ${rootStyle['justify-content-center']}`}>
                                <div className={`${rootStyle['col-md-12']}`}>
                                    <div className={`${rootStyle['card-group']}`}>
                                        <div className={`${rootStyle['card']} ${rootStyle['p-4']} ${commonStyle['card-n-m']}`}>
                                            <div className={`${rootStyle['card-body']}`}>
                                                {
                                                    !this.state.enter_mfa_code_opened ? (
                                                        <>
                                                            <h2>Login</h2>
                                                            <form onSubmit={this.submit}>
                                                                <div className={`${rootStyle['input-group']} ${rootStyle['mb-3']}`}>
                                                                    <div className={`${rootStyle['input-group-prepend']}`}>
                                                                    </div>
                                                                    <input className={`${rootStyle['form-control']}`} type="text"
                                                                        id="username"
                                                                        placeholder="User name"
                                                                        name="username"
                                                                        label="E-mail"
                                                                        value={this.state.email}
                                                                        onChange={this.handleChange('email')}
                                                                        disabled={this.state.is_loading}
                                                                    />
                                                                </div>
                                                                <div className={`${rootStyle['input-group']} ${rootStyle['mb-4']}`}>
                                                                    <div className={`${rootStyle['input-group-prepend']}`}>

                                                                    </div>
                                                                    <input className={`${rootStyle['form-control']}`} id="password"
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        label="Password"
                                                                        type="password"
                                                                        value={this.state.password}
                                                                        onChange={this.handleChange('password')}
                                                                        disabled={this.state.is_loading}
                                                                    />
                                                                </div>

                                                                <div className={commonStyle['input-error']}>
                                                                    {this.state.error_message}
                                                                </div>


                                                                <div className={`${rootStyle['row']}`}>
                                                                    <div className={`${rootStyle['col-12']}`}>
                                                                        <button variant="raised"
                                                                                type="submit"
                                                                                color="primary"
                                                                                disabled={this.state.is_loading}
                                                                                className={`${rootStyle['btn']} ${rootStyle['btn-primary']} ${rootStyle['px-4']}`}
                                                                        >
                                                                            {this.state.is_loading && <CircularProgress size={25} thickness={2}/>}
                                                                            Login
                                                                        </button>
                                                                    </div>
                                                                    <div className={`${rootStyle['col-12']} `}>
                                                                        <button onClick={this.onClickForgottenPassword}
                                                                                className={`${rootStyle['btn']} ${rootStyle['btn-link']} ${rootStyle['px-0']}`}
                                                                                type="button">Forgot Your password?
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <h3>Multi-Factor Authentication</h3>
                                                            <form onSubmit={this.submitUserCode}>
                                                                <div className={`${rootStyle['input-group']}`}>
                                                                    <input type="text"
                                                                        id="authetication_code"
                                                                        placeholder="authentication code"
                                                                        name="authentication code"
                                                                        disabled={this.state.submitLoading}
                                                                        value={this.state.userCode}
                                                                        maxLength={6}
                                                                        required
                                                                        onChange={this.handleChange('userCode')}
                                                                        className={`${rootStyle['form-control']}`}
                                                                        autoFocus
                                                                    />                                                                
                                                                </div>
                                                                <div 
                                                                    className={`${rootStyle['mb-4']}`}
                                                                    style={{
                                                                        color: '#878787',
                                                                        fontSize: '11px',
                                                                        marginTop: '5px'
                                                                    }}>
                                                                        Enter the code from your authenticator app
                                                                </div>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    id="activateM"
                                                                    checked={this.state.remember_device}
                                                                    onChange={()=>{
                                                                        this.setState(state=>{
                                                                            return {remember_device: !state.remember_device}
                                                                        })
                                                                    }}
                                                                    label={
                                                                        <span>&nbsp;This is a trusted device <IconSVGQuestionMark  data-html="true"
                                                                        className="MuiSvgIcon-root-120" 
                                                                        focusable="false" 
                                                                        aria-hidden="false" 
                                                                        height='26'
                                                                        width='18'
                                                                        data_for="remember-device"
                                                                        data-tip={"You won't be asked to enter MFA authorization code next time you login from this device."}
                                                                        /> </span>
                                                                    }
                                                                />
                                                                <div className={commonStyle['input-error']}>
                                                                    {this.state.error_message}
                                                                </div>
                                                                <div className={`${rootStyle['row']}`}>
                                                                    <div className={`${rootStyle['col-12']}`}>
                                                                        <button variant="raised"
                                                                                type="submit"
                                                                                color="primary"
                                                                                className={`${rootStyle['btn']} ${rootStyle['btn-primary']} ${rootStyle['px-4']}`}
                                                                                disabled={this.state.submitLoading}
                                                                        >
                                                                            {this.state.submitLoading && <CircularProgress size={16} thickness={4}/>}
                                                                            Verify Code
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Paper>
            </React.Fragment>
        );
    }
};

export default MyLoginPage;