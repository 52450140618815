import React, {useState, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import rootStyle from "../styles/style.module.css";
import commonStyle from "../styles/common.module.css";
import { doRequest } from "./helpers";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import useTable from './Table/useTable';
import { TableBody, TableRow, TableCell, TextField , InputAdornment, Grid, Typography} from '@material-ui/core';
import { makeStyles} from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';
import tableStyle from '../styles/table.module.css';
import {doImpersonate, getWhetherServiceRunning} from './helpers';

const useStyles = makeStyles(theme => ({
  tblWrapper: {
    marginTop: '30px',
  },
  noRecordFound: {
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#20a8d8',
    },
  },
  formLabel: {
    color: '#20a8d8 !important',
  },
}));



const TableRender = ({records, running_dwh_instances, running_airflow_clusters, running_metabase_clusters, tableHeader, laodingClients}) => {
  const classes = useStyles();
  const headCells = [
    {id: 'client_id', label: 'Client Id'},
    {id: 'email', label: 'Email'},
    {id: 'client_name', label: 'Client Name'},
    {id: 'etl_prod', label: 'ETL Prod'},
    {id: 'etl_dev', label: 'ETL Dev'},
    {id: 'metabase', label: 'Metabase'},
    {id: 'dwh', label: 'DWH'},
    {id: 'action', label: 'Action', disableSorting: true}
  ];

  const [searchValue, setSearchValue] = useState('');
  const [filterFn] = useState({fn: items => items});
  const [btnDisable, setBtnDisable] = useState(false);
  const [tableRecords, setTableRecords] = useState([]);
  const {TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting} = useTable(tableRecords, headCells, filterFn);
  useEffect(()=>{
    handleSearch(searchValue);
  },[searchValue]);

  useEffect(()=>{
    setTableRecords(parseRecords(records));
  },[records]);

  const parseRecords = (records)=>{
    const newRecords = records.map(record => ({
        client_id: record.client_id,
        email: record.email,
        client_name: record.client_name,
        etl_prod: getWhetherServiceRunning(record.client_id, running_airflow_clusters, "etl_prod")?'Running':'Stopped',
        etl_dev: getWhetherServiceRunning(record.client_id, running_airflow_clusters, "etl_dev")?'Running':'Stopped',
        metabase: getWhetherServiceRunning(record.client_id, running_metabase_clusters, "metabase")?'Running':'Stopped',
        dwh: getWhetherServiceRunning(record.client_id, running_dwh_instances, "dwh")?'Running':'Stopped',
      }));
    return newRecords;
  }

  const handleSearch = (query) => {
    if(query === ""){
      setTableRecords(parseRecords(records));
      return;
    }
    const newRecords =  records.filter(x => {
      if(x.client_name.includes(query) || x.client_id.includes(query) || x.email.includes(query)) return true;
      return false;
    });
    setTableRecords(parseRecords(newRecords));
  }

  const noContentRender = laodingClients?<div className={classes.noRecordFound}><CircularProgress  size={25} thickness={2}  /></div>:(<div className={classes.noRecordFound}>
          No Client Found
        </div>);
  return (
    <div className={classes.tblWrapper}>
      <Typography variant="headline" gutterBottom={true} headlineMapping={{display1: 'h1'}}>{tableHeader}</Typography>
        <Grid xs={6}>
              <TextField
              label="Search"
              fullWidth
              id="input-with-icon-adornment"
              value={searchValue}
              onChange={e=>setSearchValue(e.target.value)}
              InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>,
                  classes: {
                    underline: classes.cssUnderline,
                  }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.formLabel,
                }
              }}
            />
        </Grid>
      <div style={{overflowY: 'auto'}}>
          <TblContainer>
              <TblHead />
              <TableBody>
                {
                  recordsAfterPagingAndSorting().map(record => (
                    <>
                    <Link to={`/clients/${record.client_id}`}></Link>
                    <TableRow key={record.client_id}>
                        <TableCell>{record.client_id}</TableCell>
                        <TableCell>{record.email}</TableCell>
                        <TableCell>{record.client_name}</TableCell>
                        <TableCell>{record.etl_prod}</TableCell>
                        <TableCell>{record.etl_dev}</TableCell>
                        <TableCell>{record.metabase}</TableCell>
                        <TableCell>{record.dwh}</TableCell>
                        <TableCell>
                          <button onClick={() => {setBtnDisable(true);doImpersonate(record.client_id, ()=>{setBtnDisable(false)})}}
                            className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'], !btnDisable ? rootStyle['btn-primary'] : null)}
                            disabled={btnDisable}>
                            Impersonate
                          </button>
                        </TableCell>
                    </TableRow>
                    </>
                  ))
                }
              </TableBody>
          </TblContainer>
      </div>

      {recordsAfterPagingAndSorting().length !== 0 ? <TblPagination /> : noContentRender}
    </div>
  )
}
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      paidClients:[],
      trialClients: [],
      laodingClients: false,
      runningDwhInstances: [],
      runningAirflowClusters: [],
      runningMetabaseClusters: [],
    };
  }

  componentDidMount() {
    this.getClients();
  }

  async getClients() {
    this.setState({ laodingClients: true });
    let res = await doRequest("GET", "/admin_list_clients", null);
    this.setState({ laodingClients: false });
    if (res.success) {
      this.setState({ clients: res.data,paidClients: res.data.paid_clients, trialClients: res.data.trial_clients, runningDwhInstances: res.data.running_dwh_instances, runningAirflowClusters: res.data.running_airflow_clusters, runningMetabaseClusters:  res.data.running_metabase_clusters });
    } else {
      this.setState({ errorMsg: res.message });
    }
  }

  handleChange = (e) => {
    let search = e ? e.target.value.toLowerCase() : "";

    this.setState({
      search,
    });
  };
  getFilteredData() {
    return this.state.paidClients.filter(
      (i) =>
        !this.state.search ||
        i.client_name.toLowerCase().includes(this.state.search)
    );
  }

  gteDWHStatus(client_id){
    // console.log(this.state.clients.running_dwh_instances)
    const running_dwh_instances = this.state.clients.running_dwh_instances;
    const clientDWHStatus = running_dwh_instances.filter((item) => {
        return item.DBInstanceIdentifier.includes(client_id);
    });

    if (clientDWHStatus.length) {
      return 'ON'
    }else{
      return 'OFF'
    }
  }

  gteAirflowStatus(client_id){
    const running_airflow_clusters = this.state.clients.running_airflow_clusters;
    const clientAirflowStatus = running_airflow_clusters.filter((item) => {
        return item.includes(client_id);
    });

    if (clientAirflowStatus.length) {
      return 'ON'
    }else{
      return 'OFF'
    }
  }

  gteMetabaseStatus(client_id){
    const running_metabase_clusters = this.state.clients.running_metabase_clusters;
    const clientMetabaseStatus = running_metabase_clusters.filter((item) => {
        return item.includes(client_id);
    });

    if (clientMetabaseStatus.length) {
      return 'ON'
    }else{
      return 'OFF'
    }
  }

  render() {
    return (
      <>
        <Paper style={{ padding: 5 }} className={commonStyle["default"]}>
          <div className={rootStyle["container-fluid"]}>
            <div className={rootStyle.card}>
              <div
                className={rootStyle["card-header"]}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className={commonStyle["headText"]}>Admin Dashboard</div>
                  <div>
                    <div></div>
                  </div>
                </div>
                <div></div>
              </div>
              <div className={rootStyle["card-body"]}>
                <div className={rootStyle["row"]}>
                  <div className={`${rootStyle["col-sm-3"]}`}>
                    <div className={rootStyle["card-body"]}>
                      <div className={rootStyle.card}>
                        <div
                          className={rootStyle["card-header"]}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div className={commonStyle["headText"]}>
                              Total Clients
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${rootStyle["card-body"]} ${rootStyle["text-center"]}`}
                        >
                          {this.state.clients.total_estimated_clients || 0}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 2 */}
                  <div className={`${rootStyle["col-sm-3"]}`}>
                    <div className={rootStyle["card-body"]}>
                      <div className={rootStyle.card}>
                        <div
                          className={rootStyle["card-header"]}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div className={commonStyle["headText"]}>
                              Total Airflow
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${rootStyle["card-body"]} ${rootStyle["text-center"]}`}
                        >
                          {this.state.clients.running_airflow_clusters ? (
                            <>
                              {
                                this.state.clients.running_airflow_clusters
                                  .length
                              }
                            </>
                          ) : (
                            <>0</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 3 */}
                  <div className={`${rootStyle["col-sm-3"]}`}>
                    <div className={rootStyle["card-body"]}>
                      <div className={rootStyle.card}>
                        <div
                          className={rootStyle["card-header"]}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div className={commonStyle["headText"]}>
                              Total DWH
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${rootStyle["card-body"]} ${rootStyle["text-center"]}`}
                        >
                          {this.state.clients.running_dwh_instances ? (
                            <>
                              {this.state.clients.running_dwh_instances.length}
                            </>
                          ) : (
                            <>0</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 4 */}
                  <div className={`${rootStyle["col-sm-3"]}`}>
                    <div className={rootStyle["card-body"]}>
                      <div className={rootStyle.card}>
                        <div
                          className={rootStyle["card-header"]}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <div
                              style={{ fontSize: "17px" }}
                              className={commonStyle["headText"]}
                            >
                              Total Metabase
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${rootStyle["card-body"]} ${rootStyle["text-center"]}`}
                        >
                          {this.state.clients.running_metabase_clusters ? (
                            <>
                              {
                                this.state.clients.running_metabase_clusters
                                  .length
                              }
                            </>
                          ) : (
                            <>0</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* --- */}
                </div>
                {/* Subscribed clients */}
                <TableRender records={this.state.paidClients}
                  running_dwh_instances={this.state.runningDwhInstances}
                  running_airflow_clusters={this.state.runningAirflowClusters}
                  running_metabase_clusters={this.state.runningMetabaseClusters}
                  tableHeader="Subscribed"
                  laodingClients={this.state.laodingClients}
                />
                {/* Trial clients */}
                <TableRender records={this.state.trialClients}
                  running_dwh_instances={this.state.runningDwhInstances}
                  running_airflow_clusters={this.state.runningAirflowClusters}
                  running_metabase_clusters={this.state.runningMetabaseClusters}
                  tableHeader="Trial"
                  laodingClients={this.state.laodingClients}
                />
              </div>
            </div>
          </div>
        </Paper>
      </>
    );
  }
}

export default Dashboard;
