import React, {useState, useEffect} from 'react'
import { Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel } from '@material-ui/core';
import { makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: '10px',
        overflowY: 'auto',
        '& thead th': {
            fontWeight: '600',
            color: '#2f353a',
            backgroundColor: '#f0f3f5',
            fontSize: '13px',
            paddingRight: 0,
        },
        '& tbody a': {
            position: 'absolute',
            height: '48px',
            width: '100%',
        },
        '& tbody td':{
            fontWeight: '300',
            paddingRight: 0,

        },
        '& tbody tr:hover':{
            backgroundColor: '#f8f8f8',
        }
    }
}))

const useTable = (records, headCells) => {
    const classes = useStyles();
    
    const pages = [5, 10, 25];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState()

    useEffect(()=>{
        setPage(0);
    }, [records])
    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    );

    const TblHead = props => {

        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === "asc";
            setOrder(isAsc?'desc':'asc');
            setOrderBy(cellId);
        };

        return <TableHead>
            <TableRow>
                {
                    headCells.map(headCell => (
                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id? order: false}>
                            {headCell.disableSorting?headCell.label: (<TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id? order : 'asc'}
                                onClick={()=>handleSortRequest(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>)}
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
            };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const TblPagination = () => (
        <TablePagination 
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={records.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

    const stableSort = (array, comparator) => {
        const stablizedThis = array.map((el, index) => [el, index]);
        stablizedThis.sort((a,b) => {
            const order = comparator(a[0], b[0]);
            if(order !== 0) return order;
            return a[1] = b[1];
        });
        return stablizedThis.map(el => el[0]);
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'?
            (a, b) => descendingComparator(a, b, orderBy):
            (a, b) => -descendingComparator(a, b, orderBy);
    }

    const descendingComparator = (a, b, orderBy) => {
        if(a[orderBy] && b[orderBy]){
            if(b[orderBy].toLowerCase() < a[orderBy].toLowerCase()){
                return -1;
            }
            if(b[orderBy].toLowerCase() > a[orderBy].toLowerCase()){
                return 1;
            }
        }
        return 0;
    }

    const recordsAfterPagingAndSorting = () => {
        let newRecords = records;
        newRecords = newRecords.slice(page*rowsPerPage, (page+1)*rowsPerPage);
        return stableSort(newRecords, getComparator(order, orderBy));
    }

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    }
}

export default useTable
