import styled from 'styled-components';

export const CardWrapper = styled.div`
    border-radius: 0.75rem;
    border: initial;
    background: #fff;
    box-shadow: 0 1px 15px #0000000a,0 1px 6px #0000000a;
    height: 190px;
    width: 300px;
    border-radius: .75rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i{
        font-size: 46px;
        line-height: 66px;
        color: #6fb327;
    }
    h3{
        color: #8f8f8f;
        font-size: 1.8rem;
        font-weight: 600;
        margin:0;
    }
    p{
        font-weight: 600;
        margin: 0;
        span.stopped{
                color: #c43d4b;
        }
        span.running{
            color: #6fb327;
        }
    }
    
`