import React from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import moment from 'moment-timezone';

import CardHeader from './CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import {doRequest} from "./helpers";

import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';
import tableStyle from '../styles/table.module.css';
import ErrorMessage from "./ErrorMessage";

moment.tz.setDefault('UTC');

const styles = {
    root: {
        marginBottom: 16
    },
    content: {
        backgroundColor: '#43a047',
    }
};

class HomeClientSecretsParameters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clientId: '',
            clients: [],
            parameters: [],
            secrets: [],
            loadingClients: false,
            loadingResult: false,
            error: '',
        };

        // this.submitQuery = this.submitQuery.bind(this);
    }

    componentDidMount() {
        // this.getClientOptions();
    }

    async getClientOptions() {
        this.setState({loadingClients: true});
        let res = await doRequest('GET', '/admin_list_clients', null)
        this.setState({loadingClients: false});

        if (res.success) {
            this.setState({ clients: res.data });
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    async submitQuery() {
        this.setState({error: '', parameters: [], secrets: []});

        var has_error = false;
        if (this.state.clientId.length === 0) {
            has_error = true;
        }
        if (has_error) {
            this.setState({error: 'Fill all fields'});
            return;
        }

        this.setState({loadingResult: true});
        let res = await doRequest('POST', '/admin_list_client_secrets_parameters', {
            client_id: this.state.clientId,
        });
        this.setState({loadingResult: false});

        if (res.success) {
            if (res.data.length === 0) {
                alert('No data found');
                return;
            }

            this.setState({ parameters: res.data.parameters, secrets: res.data.secrets});
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    render() {
        return (
            <Paper style={{ padding: 5 }} className={commonStyle['default']}>
                <div className={rootStyle['container-fluid']}>
                    <div className={rootStyle.card}>
                        <CardHeader
                            title='Secrets and Parameters'
                            description=""
                        />
                        <div className={rootStyle['card-body']}>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="client-select" className={tableStyle['form-left-label']}>Client:</label>
                                    <select
                                        className={rootStyle["custom-select"]}
                                        onChange={(e) => {
                                            this.setState({ clientId: e.target.value });
                                        }}
                                        id="client-select"
                                        value={this.state.clientId}>
                                        <option value=''>Select Client</option>
                                        {this.state.clients && this.state.clients.length > 0 ?
                                            this.state.clients.map((o, i) => {
                                                return (
                                                    <option key={i} value={o['client_id']}>{o['client_name']}</option>
                                                );
                                            })
                                            : null}
                                    </select>
                                </div>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label className={tableStyle['form-left-label']}>&nbsp;</label>
                                    <button
                                        type="submit"
                                        className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'])}
                                        onClick={() => {this.submitQuery()}}
                                    >
                                        {this.state.loadingResult && <CircularProgress style={{ position: 'absolute' }} size={25} thickness={2} />}
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <ErrorMessage msg={this.state.error}/>

                            <div className={rootStyle['row']}>
                                <div className={tableStyle['table-wrapper']}>
                                    <h3>Secrets</h3>
                                    <table style={{marginBottom: 20}}>
                                        <thead className={`${tableStyle["table-head"]}`}>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.secrets.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td style={{textAlign: 'left'}}>{item['name']}</td>
                                                    <td>{item['description']}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                    <h3>Parameters</h3>
                                    <table>
                                        <thead className={`${tableStyle["table-head"]}`}>
                                        <tr>
                                            <th>Name</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.parameters.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td style={{textAlign: 'left'}}>{item['name']}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(HomeClientSecretsParameters)