import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Close from '@material-ui/icons/Close';

import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';

import onClickOutside from 'react-onclickoutside'

class FormDialog extends React.Component {
    state = {
        open: this.props.open,
        email: '',
        step2_open: false,
        error_message: false,
        is_loading: false,
        cognito_user: null,
        code_delivery_details: null
    };

    handleClickOutside = () => {
        this.props.closeAll();
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    componentDidUpdate = () => {
        if (this.props.open !== this.state.open) {
            this.setState({open: this.props.open})
        }
    };

    handleSubmit = async (e) => {
        var me = this;
        e.preventDefault();

        if (this.state.email.length === 0) {
            this.setState({error_message: "All fields are required"});
            return;
        }

        const poolData = {
            UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
        };
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        const userData = {
            Username: this.state.email,
            Pool: userPool
        }
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function(result) {
                // me.setState({step2_open: true, cognito_user: cognitoUser, code_delivery_details: result.CodeDeliveryDetails})
                //set_cognito_user_name_details

                me.props.set_cognito_user_name_details(cognitoUser , result.CodeDeliveryDetails);
            },
            onFailure: function(err) {
                console.log(err)
                alert(err.message);
            }
        });
    };

    closeCodeModal = (e) => {
        this.setState({step2_open: false})
    }

    render() {
        return (
            <div>
                <DialogContent>
                    <div className={`${rootStyle['container-fluid']} ${commonStyle['modal-container']}`}>
                        <div className={`${rootStyle['row']} `}>
                            <div className={`${rootStyle['col-md-12']}`}>
                                <p className={commonStyle['p-n-m']}>
                                    <Close onClick={this.props.close} />
                                </p>
                                <div className={`${rootStyle['card-group']}`}>
                                    <div className={`${rootStyle['card']} ${rootStyle['p-4']} ${commonStyle['card']}`}>
                                        <div className={`${rootStyle['card-body']} ${commonStyle['card-body-np']}`}>
                                            <h2>Forgotten password</h2>
                                            <form onSubmit={e => this.handleSubmit(e)}>
                                                <div className={`${rootStyle['input-group']} ${rootStyle['mb-3']}`}>
                                                    <div className={`${rootStyle['input-group-prepend']}`}>

                                                    </div>
                                                    <input className={`${rootStyle['form-control']}`} name="email"
                                                           placeholder="Email"
                                                           label="E-mail"
                                                           type="text"
                                                           value={this.state.email}
                                                           onChange={this.handleChange('email')}
                                                           disabled={this.state.is_loading}
                                                           required
                                                    />
                                                </div>

                                                <div className={commonStyle['input-error']}>
                                                    {this.state.error_message}
                                                </div>


                                                <div className={`${rootStyle['row']}`}>
                                                    <div className={`${rootStyle['col-12']}`}>
                                                        <button variant="raised"
                                                                type="submit"
                                                                color="primary"
                                                                className={`${rootStyle['btn']} ${rootStyle['btn-primary']} ${rootStyle['px-4']}`}
                                                        >

                                                            Reset password
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
        );
    }
}

export default onClickOutside(FormDialog);