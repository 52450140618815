import React from "react";
import Paper from "@material-ui/core/Paper";

import CardHeader from "./CardHeader";
import rootStyle from "../styles/style.module.css";
import commonStyle from "../styles/common.module.css";
import {doRequest, getWhetherServiceRunning, showAlert} from "./helpers";
import Card from './Card/Card';
import ClientDetails from "./ClientDetails/ClientDetails";
import CircularProgress from '@material-ui/core/CircularProgress';

class ClientStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientDetail: {},
      loadingClients: true,
      running_airflow_clusters: [],
      running_metabase_clusters: [],
      running_dwh_instances: [],
    };

  }


  componentDidMount() {
    this.getClients();
  }

  async getClients(setLoading = true) {
    const { match } = this.props;
    const clientId = match.params.id;
    const payload = {
      client_id: clientId,
    }
    if(setLoading){
      this.setState({loadingClients: true});
    }
    let res = await doRequest('GET', '/admin_list_clients', null);
    let res2 = await doRequest('POST','/admin_search_client', payload);
    if(setLoading){
      this.setState({loadingClients: false});
    }

    if (res.success && res2.success) {
        this.setState({ clientDetail: res2.data,
            searchClients:res.data,
            running_airflow_clusters: res.data.running_airflow_clusters,
            running_dwh_instances: res.data.running_dwh_instances,
            running_metabase_clusters: res.data.running_metabase_clusters,
          });
    } else {
        showAlert('error', res.message || res2.message)
    }
}

handleOnChange = async (e)=>{
  const payload = {
    "client_id": this.state.clientDetail.client_id,
    "misc_attributes" : {
      "allow.dwh": e.dwh,
      "allow.etl": e.etl,
      "allow.reporting": e.reports,
    }
  }
  let res = await doRequest('POST', '/set_client_props', payload);
  if(res.success){
    this.getClients(false);
  }else{
    this.getClients(false);
    showAlert('error', res.message);
  }
}

handlePayeeChange = async (e)=>{
  const payload = {
    "client_id": this.state.clientDetail.client_id,
    "process_integrations": e?1:0,
  }
  let res = await doRequest('POST', '/set_client_props', payload);
  if(res.success){
    this.getClients(false);
  }else{
    this.getClients(false);
    showAlert('error', res.message);
  }
}

  render() {
    const {
      clientDetail,
      loadingClients,
      running_airflow_clusters,
      running_metabase_clusters,
      running_dwh_instances,
    } = this.state;
    return (
      <Paper style={{ padding: 5 }} className={commonStyle["default"]}>
        <div className={rootStyle["container-fluid"]}>
          <div className={rootStyle.card}>
            <CardHeader title="Client Stats" description="" />
            <div className={rootStyle["card-body"]}>
            {!loadingClients ? <>
               {/* Cards */}
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <Card style={{margin: '15px'}}>
                    <i className="iconsminds-data-storage" />
                    <h3>DWH</h3>
                    <p>{getWhetherServiceRunning(clientDetail.client_id, running_dwh_instances, 'dwh')?<span className="running">Running</span>:<span className="stopped">Stopped</span>}</p>
                  </Card>
                <Card style={{margin: '15px'}}>
                  <i className="iconsminds-refresh" />
                  <h3>ETL</h3>
                  <p>Production: {getWhetherServiceRunning(clientDetail.client_id, running_airflow_clusters, 'etl_prod')?<span className="running">Running</span>:<span className="stopped">Stopped</span>}</p>
                  <p>Development: {getWhetherServiceRunning(clientDetail.client_id, running_airflow_clusters, 'etl_dev')?<span className="running">Running</span>:<span className="stopped">Stopped</span>}</p>
                </Card>
                <Card style={{margin: '15px'}}>
                  <i className="iconsminds-bar-chart-4" />
                  <h3>Metabase</h3>
                  <p>{getWhetherServiceRunning(clientDetail.client_id, running_metabase_clusters, 'metabase')?<span className="running">Running</span>:<span className="stopped">Stopped</span>}</p>
                </Card>
             </div>
             <ClientDetails handleOnChange={this.handleOnChange} handlePayeeChange={this.handlePayeeChange} clientDetails={{
               clientId: clientDetail.client_id,
               clientName: clientDetail.name,
                companyName: clientDetail.client_name,
                email: clientDetail.email,
                lastLoginAt: clientDetail.last_login,
                createdDate: clientDetail.created,
                misc_attributes: clientDetail.misc_attributes,
                process_integrations: clientDetail.process_integrations,
             }}/>
            </>:<div style={{width: '100%', height: '65vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <CircularProgress />
              </div>}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

export default ClientStats;
