import React from 'react';

import rootStyle from '../styles/style.module.css';

class SuccessMessage extends React.Component {

    render() {
        return (
            <React.Fragment>
                {this.props.msg !== '' &&
                <div className={`${rootStyle['alert']} ${rootStyle['alert-success']}`} role="alert">
                    {this.props.msg}
                </div>}
            </React.Fragment>
        )
    }
}

export default SuccessMessage;