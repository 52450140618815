import React, {useState, useEffect} from 'react';
import {AppBar} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import {IconButton, Tooltip, Menu} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Details from './AppBar/Details';
import {doRequest, showAlert} from './helpers';
import ImpersonatedDetails from './AppBar/ImpersonatedDetails';
const styles = {};

const MyAppBar = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [accountDetails, setAccountDetails] = useState({});

    useEffect(() => {
        window.addEventListener('storage', event => {
            checkSessionStorage();
        });
        async function fetchData() {
            try {
                const response = await doRequest(
                    'GET',
                    '/get_client_data',
                    null,
                );
                setSessionStorage(
                    response.data.email,
                    response.data.client_id,
                    response.data.client_name,
                    response.data.user_email,
                );
                setAccountDetails(current => ({
                    ...current,
                    email: response.data.email,
                    companyName: response.data.client_name,
                    clientId: response.data.client_id,
                    userEmail: response.data.user_email,
                }));
            } catch (err) {
                showAlert(
                    'error',
                    'Some error occurred while fetching account details',
                );
            }
        }
        fetchData();
        return () => {
            window.removeEventListener('storage', () => {});
        };
    }, []);

    const setSessionStorage = (email, clientId, company, userEmail) => {
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('companyName', company);
        sessionStorage.setItem('clientId', clientId);
        sessionStorage.setItem('userEmail', userEmail);
    };

    const checkSessionStorage = () => {
        const ssEmail = sessionStorage.getItem('email');
        const ssCompanyName = sessionStorage.getItem('companyName');
        const ssClientId = sessionStorage.getItem('clientId');
        const ssUserEmail = sessionStorage.getItem('userEmail');
        if (!ssClientId) {
            return false;
        }
        setAccountDetails(current => ({
            ...current,
            email: ssEmail,
            companyName: ssCompanyName,
            clientId: ssClientId,
            userEmail: ssUserEmail,
        }));
        return true;
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar {...props} className="admin-appbar">
            <Details accountDetails={accountDetails} />
            <Tooltip title="Impersonated details">
                <IconButton
                    aria-describedby="my-id"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}
            >
                <ImpersonatedDetails accountDetails={accountDetails} />
            </Menu>
        </AppBar>
    );
};

export default withStyles(styles)(MyAppBar);
