import React from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment-timezone';

import CardHeader from './CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';

import {doRequest} from "./helpers";

import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';
import tableStyle from '../styles/table.module.css';

moment.tz.setDefault('UTC');

const styles = {
    root: {
        marginBottom: 16
    },
    content: {
        backgroundColor: '#43a047',
    }
};

class HomeLastRunStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            result: [],
            loadingResult: false,
            error: null,
        };

        // this.submitQuery = this.submitQuery.bind(this);
    }

    componentDidMount() {
        this.getServices();
    }

    async getServices() {
        this.setState({loadingResult: true});
        let res = await doRequest('GET', '/admin_list_services_statuses', null)
        this.setState({loadingResult: false});

        if (res.success) {
            this.setState({ result: res.data, error: null });
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    render() {
        let { error } = this.state;

        return (
            <Paper style={{ padding: 5 }} className={commonStyle['default']}>
                <div className={rootStyle['container-fluid']}>
                    <div className={rootStyle.card}>
                        <CardHeader
                            title='Last Service Statuses'
                            description=""
                        />
                        <div className={rootStyle['card-body']}>
                            {error &&
                            <div className={tableStyle['error']} style={{marginBottom: 16, marginTop: 16}}>{error}</div>
                            }

                            <div className={rootStyle['row']}>
                                <div className={tableStyle['table-wrapper']}>
                                    {this.state.result && this.state.result.length > 0 && <React.Fragment>
                                        <table>
                                            <thead className={`${tableStyle["table-head"]}`}>
                                            <tr>
                                                <th>Service</th>
                                                <th>Last Run Timestamp</th>
                                                <th>Last Run Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.result.map((item, idx) => {
                                                var color = '';
                                                if (item['status'] === 'SUCCESS') {
                                                    color = 'green';
                                                }
                                                if (item['status'] === 'ERROR') {
                                                    color = 'red';
                                                }
                                                var dateobj = new Date(item['start_ts']+'+00:00');
                                                // TODO: can moment.js load from UTC by default?
                                                if (isNaN(dateobj)) {
                                                    dateobj = '-';
                                                } else {
                                                    dateobj = moment(dateobj).format('DD.MM.YYYY HH:mm:ss') + ' UTC';
                                                }
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item['service_name']}</td>
                                                        <td>{dateobj}</td>
                                                        <td style={{color: color}}>{item['status']}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </React.Fragment>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(HomeLastRunStatus)