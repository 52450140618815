import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ResetForm1 from  './ResetForm1'

import rootStyle from '../styles/style.module.css';




export default class FormDialog extends React.Component {

    render() {
        return (
            <Dialog
                className={`${rootStyle['container']}`}
                open={this.props.open}
                // onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <ResetForm1 set_cognito_user_name_details={this.props.set_cognito_user_name_details} open={this.props.open} close={this.props.close} closeAll={this.props.closeAll} />


            </Dialog>
        );
    }
}