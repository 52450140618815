import React from 'react';
import { Route } from 'react-router-dom';
import HomeRawApiData from "../../admin-app/src/components/HomeRawApiData";
import HomeLastRunStatus from "../../admin-app/src/components/HomeLastRunStatus";
import HomeRetriggerLoading from "../../admin-app/src/components/HomeRetriggerLoading";
import HomeClientSecretsParameters from "../../admin-app/src/components/HomeClientSecretsParameters";
import HomeClients from "../../admin-app/src/components/HomeClients";
import ClientStats from './components/ClientStats';

export default [
    <Route exact path='/raw-api-data' component={HomeRawApiData} />,
    <Route exact path='/last-runs' component={HomeLastRunStatus} />,
    <Route exact path='/retrigger-loading' component={HomeRetriggerLoading} />,
    <Route exact path='/client-secrets-parameters' component={HomeClientSecretsParameters} />,
    <Route exact path='/clients' component={HomeClients} />,
    <Route exact path='/clients/:id' component={ClientStats} />,
];