import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ResetForm2 from  './ResetForm2'

import rootStyle from '../styles/style.module.css';




export default class FormDialog extends React.Component {

    render() {
        return (
            <Dialog
                className={`${rootStyle['container']}`}
                open={this.props.open}
                // onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <ResetForm2
                    cognito_user={this.props.cognito_user}
                    code_delivery_details={this.props.code_delivery_details}
                    open={this.props.open}
                    close={this.props.close}
                    closeAll={this.props.closeAll} />


            </Dialog>
        );
    }
}