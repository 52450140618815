import React from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import moment from 'moment-timezone';

import CardHeader from './CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import { doRequest } from "./helpers";
import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';
import tableStyle from '../styles/table.module.css';
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import AuthUtils from '../api/AuthUtils'
import { showAlert } from './helpers';
import ClientDetails from './ClientDetails/ClientDetails';

moment.tz.setDefault('UTC');

const styles = {
    root: {
        marginBottom: 16
    },
    content: {
        backgroundColor: '#43a047',
    }
};

class HomeClients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            loadingClients: false,
            email: '',
            client_name: '',
            subdomain: '',
            password: '',
            repeat_password: '',
            errorMsg: '',
            successMsg: '',
            searchClients: [],
            searchEmail: '',
            searchClientId: '',
            client_details: {},
            searchErrorMsg: '',
            loading: false,
            client_aatr_details: [],
            dwh: false,
            etl: false,
            reports: false,
            allowLoading: false,
            impersonateLoading: false,
            impersonateDisabled: false,
        };

        // this.submitQuery = this.submitQuery.bind(this);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            if (this.state.searchClientId !== '') {
                this.setState({ searchEmail: '' })
            }
            if (this.state.searchEmail !== '') {
                this.setState({ searchClientId: '' })
            }
        });
    };

    componentDidMount() {
        // this.getClients();
    }

    async getClients(setLoading = true) {
        if(setLoading){
            this.setState({ loadingClients: true });
        }
        let res = await doRequest('GET', '/admin_list_clients', null)
        if(setLoading){
            this.setState({ loadingClients: false });
        }

        if (res.success) {
            const activeClients = res.data.paid_clients.filter((item) => {
                return item.process_integrations === '1';
            });
            this.setState({
                clients: activeClients,
                searchClients: res.data
            });
        } else {
            this.setState({ errorMsg: res.message });
        }
    }

    onSearchInput = event => {
        if (event.target.value.length) {
            let searchedData = this.state.clients.filter(item => {
                return item.client_id.toLowerCase().includes(event.target.value.toLowerCase())
                    || item.client_name.toLowerCase().includes(event.target.value.toLowerCase())
                    || item.email.toLowerCase().includes(event.target.value.toLowerCase());
            })

            this.setState({
                searchClients: [...searchedData],
                searchKey: event.target.value
            })
        } else {
            this.setState({
                searchClients: [...this.state.clients],
                searchKey: event.target.value
            })
        }
    }

    async submit() {
        this.setState({ errorMsg: '', successMsg: '' });

        var has_error = false;
        if (this.state.email.length === 0) {
            has_error = true;
        }
        if (this.state.client_name.length === 0) {
            has_error = true;
        }
        if (this.state.password.length === 0) {
            has_error = true;
        }
        if (this.state.repeat_password.length === 0) {
            has_error = true;
        }
        if (this.state.subdomain.length === 0) {
            has_error = true;
        }
        if (has_error) {
            this.setState({ errorMsg: 'Fill all fields', successMsg: '' });
            return;
        }

        // Cognito policy for passwords: min 8 characters, requires numbers, special characters, uppercase and lowercase letters
        if (this.state.password.length < 8) {
            this.setState({ errorMsg: 'Password min length: 8 symbols', successMsg: '' });
            return;
        }
        if (!this.state.password.match(/[a-z]/)) {
            this.setState({ errorMsg: 'Password requires at least one lowercase letter', successMsg: '' });
            return;
        }
        if (!this.state.password.match(/[A-Z]/)) {
            this.setState({ errorMsg: 'Password requires at least one uppercase letter', successMsg: '' });
            return;
        }
        if (!this.state.password.match(/[0-9]/)) {
            this.setState({ errorMsg: 'Password requires at least one number', successMsg: '' });
            return;
        }
        if (!this.state.password.match(/[^a-zA-Z0-9]/)) {
            this.setState({ errorMsg: 'Password requires at least one special character', successMsg: '' });
            return;
        }
        // end of password policy check

        if (this.state.password !== this.state.repeat_password) {
            this.setState({ errorMsg: 'Password does not match', successMsg: '' });
            return;
        }

        this.setState({ loadingResult: true });
        let res = await doRequest('POST', '/admin_add_client', {
            email: this.state.email,
            client_name: this.state.client_name,
            password: this.state.password,
            subdomain: this.state.subdomain,
            user_pool_id: process.env.REACT_APP_COGNITO_USER_POOL_ID
        });
        this.setState({ loadingResult: false });

        if (res.success) {
            this.setState({ successMsg: res.message });
            // this.getClients();
        } else {
            this.setState({ errorMsg: res.message });
        }
    }

    viewClient = async (showLoading = true) => {
        let { searchEmail, searchClientId } = this.state;
        let error = '';
        if (searchEmail !== '') {
            if (/.+@.+\..+/.test(searchEmail) === false) {
                error = 'E-mail is not valid';
            }
        }
        if (searchEmail === '' && searchClientId === '') {
            error = 'Email or Client ID is required';
        }
        if (error) {
            this.setState({ searchErrorMsg: error });
            return;
        }
        let data = {};
        if (searchEmail !== '') {
            data = { "email": searchEmail }
        }
        else if (searchClientId !== '') {
            data = { "client_id": searchClientId }
        }

        if(showLoading){
            this.setState({ loading: true, searchErrorMsg: '' })
        }
        let res = await doRequest('POST', '/admin_search_client', data)
        if(showLoading){
            this.setState({ loading: false })
        }


        if (res.success) {
            this.setState({ client_details: res.data,
                            dwh: res.data.misc_attributes && res.data.misc_attributes['allow.dwh'],
                            etl: res.data.misc_attributes && res.data.misc_attributes['allow.etl'],
                            reports: res.data.misc_attributes && res.data.misc_attributes['allow.reporting']});

            let result = {
                company_name: res.data.client_name,
                name: res.data.name,
                client_id: res.data.client_id,
                email: res.data.email
            }

            this.setState({ client_aatr_details: result })
        }
        else {
            if (res.message !== '') {
                this.setState({ searchErrorMsg: res.message });
            }
            if (res === 'list index out of range') {
                this.setState({ searchErrorMsg: res });
            }

        }
    }

    allowServices = async (name) => {
        let { dwh, etl, reports } = this.state
        if (name === 'dwh') {
            this.setState({ dwh: !dwh }, () => this.allowDisallowServices())
        }
        if (name === 'etl') {
            this.setState({ etl: !etl }, () => this.allowDisallowServices())
        }
        if (name === 'reports') {
            this.setState({ reports: !reports }, () => this.allowDisallowServices())
        }

    }

    allowDisallowServices = async () => {
        let { dwh, etl, reports } = this.state;
        let data = {
            email: this.state.client_aatr_details.email,
            misc: "allow.dwh=" + (dwh ? 1 : 0) + ";allow.reporting=" + (reports ? 1 : 0) + ";allow.etl=" + (etl ? 1 : 0)
        }
        this.setState({ allowLoading: true })
        let res = await doRequest('POST', '/admin_set_misc_attributes_for_client', data)
        this.setState({ allowLoading: false })
        if (res.success) {
        }
    }

    async doImpersonate(clientId) {
        this.setState({
            impersonateLoading: true,
            impersonateDisabled: true,
        });
        const body = {
            client_id: clientId
        }
        try {
            const resp = await doRequest('POST', '/admin_impersonate_client', body);
            if(resp.success){
                AuthUtils.refreshToken({
                    callback: async () => {
                        try {
                            const response = await doRequest('GET', '/get_client_data', null);
                            this.setSessionStorage(response.data.email, response.data.client_name, response.data.client_id, response.data.user_email);
                            this.setState({
                                impersonateLoading: false,
                                impersonateDisabled: false,
                            });
                            showAlert('success', 'Account successfully impersonated');
                        } catch (err) {
                            this.setState({
                                impersonateLoading: false,
                                impersonateDisabled: false,
                            });
                            showAlert('error', err.message || 'Some error occurred. Try again later.');
                        }
                    }
                });
            }else{
                throw resp;
            }

        } catch (err) {
            this.setState({
                impersonateLoading: false,
                impersonateDisabled: false,
            });
            showAlert('error', err.message || 'Some error occurred. Try again later.');
        }

    }

    setSessionStorage(email, company, clientId, userEmail) {
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('companyName', company);
        sessionStorage.setItem('clientId', clientId);
        sessionStorage.setItem('userEmail', userEmail);
        window.dispatchEvent(new Event('storage'))
    }

    handleOnChange = async (e)=>{
        const payload = {
            "client_id": this.state.client_aatr_details.client_id,
            "misc_attributes" : {
            "allow.dwh": e.dwh,
            "allow.etl": e.etl,
            "allow.reporting": e.reports,
            "provision.tableau_reports": e.tableau_reports,
            }
        }
        let res = await doRequest('POST', '/set_client_props', payload);
        if(res.success){
            this.viewClient(false);
        }else{
            this.viewClient(false);
            showAlert('error', res.message);
        }
    }

    handlePayeeChange = async (e)=>{
        const payload = {
            "client_id": this.state.client_aatr_details.client_id,
            "process_integrations": e?1:0,
        }
        let res = await doRequest('POST', '/set_client_props', payload);
        if(res.success){
            this.viewClient(false);
        }else{
            this.viewClient(false);
            showAlert('error', res.message);
        }
    }


    render() {
        return (
            <>
                <Paper style={{ padding: 5 }} className={commonStyle['default']}>
                    <div className={rootStyle['container-fluid']}>
                        <div className={rootStyle.card}>
                            <CardHeader
                                title='Clients'
                                description=""
                            />
                            <div className={rootStyle['card-body']}>
                                {/* <div className={rootStyle['row']}>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                        <label htmlFor="client-select" className={tableStyle['form-left-label']}>Client Name:</label>
                                        <input className={`${rootStyle['form-control']}`} name="client_name"
                                            placeholder="Client Name"
                                            type="text"
                                            value={this.state.client_name}
                                            onChange={this.handleChange('client_name')}
                                            required
                                        />
                                    </div>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                        <label htmlFor="client-select" className={tableStyle['form-left-label']}>Email:</label>
                                        <input className={`${rootStyle['form-control']}`} name="email"
                                            placeholder="Email"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.handleChange('email')}
                                            required
                                        />
                                    </div>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                        <label htmlFor="client-select" className={tableStyle['form-left-label']}>Subdomain:</label>
                                        <input className={`${rootStyle['form-control']}`} name="subdomain"
                                            placeholder="Subdomain"
                                            type="text"
                                            value={this.state.subdomain}
                                            onChange={this.handleChange('subdomain')}
                                            required
                                        />
                                    </div>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                        <label htmlFor="client-select" className={tableStyle['form-left-label']}>Password:</label>
                                        <input className={`${rootStyle['form-control']}`} name="password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.handleChange('password')}
                                            required
                                            minLength={6}
                                        />
                                    </div>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                        <label htmlFor="client-select" className={tableStyle['form-left-label']}>Repeat Password:</label>
                                        <input className={`${rootStyle['form-control']}`} name="repeat_password"
                                            type="password"
                                            value={this.state.repeat_password}
                                            onChange={this.handleChange('repeat_password')}
                                            required
                                            minLength={6}
                                        />
                                    </div>
                                </div>
                                <div className={rootStyle['row']}>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                        <label className={tableStyle['form-left-label']}>&nbsp;</label>
                                        <button
                                            type="submit"
                                            className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'])}
                                            onClick={() => { this.submit() }}
                                        >
                                            {this.state.loadingResult && <CircularProgress style={{ position: 'absolute' }} size={25} thickness={2} />}
                                            Add
                                        </button>
                                    </div>
                                </div> */}

                                <ErrorMessage msg={this.state.errorMsg} />
                                <SuccessMessage msg={this.state.successMsg} />

                                <div className={`${rootStyle['form-group']}`}>
                                    <h3>Client Search</h3>
                                </div>

                                <div className={rootStyle['row']}>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-4']}`} >
                                        <input className={`${rootStyle['form-control']}`}
                                            type="text"
                                            id="search"
                                            placeholder="Email"
                                            value={this.state.searchEmail}
                                            onChange={this.handleChange('searchEmail')}
                                        />
                                    </div>
                                    <div className={`${rootStyle['col-sm-1']}`} style={{ textAlign: "center", marginTop: 7 }}>Or</div>
                                    <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-4']}`}>
                                        <input className={`${rootStyle['form-control']}`}
                                            type="text"
                                            id="search"
                                            placeholder="Client Id"
                                            value={this.state.searchClientId}
                                            onChange={this.handleChange('searchClientId')}
                                        />
                                    </div>
                                    <div className={`${rootStyle['col-sm-3']}`}>
                                        <button
                                            type="submit"
                                            className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'])}
                                            onClick={() => { this.viewClient() }}
                                        >
                                            {this.state.loading && <CircularProgress style={{ position: 'absolute' }} size={25} thickness={2} />}
                                            Search
                                        </button>
                                    </div>
                                </div>

                                <ErrorMessage msg={this.state.searchErrorMsg} />
                                {!this.state.loading ?
                                    <React.Fragment>
                                        {(Object.values(this.state.client_details).length > 0 && !this.state.searchErrorMsg) &&
                                            <>
                                                <ClientDetails clientDetails={{
                                                    clientId: this.state.client_aatr_details.client_id,
                                                    clientName: this.state.client_aatr_details.name,
                                                    companyName: this.state.client_aatr_details.company_name,
                                                    email: this.state.client_aatr_details.email,
                                                    lastLoginAt: this.state.client_details.last_login,
                                                    createdDate: this.state.client_details.created,
                                                    misc_attributes: this.state.client_details.misc_attributes,
                                                    process_integrations: this.state.client_details.process_integrations,
                                                }} handleOnChange={this.handleOnChange} handlePayeeChange={this.handlePayeeChange}/>
                                            </>
                                        }
                                    </React.Fragment>
                                    : <div className={`${rootStyle['loader-center']}`}>
                                        <CircularProgress style={{ position: 'absolute' }} size={25} thickness={2} />
                                    </div>
                                }
                                {/* <div className={rootStyle['row']}>
                                    {this.state.loadingClients ?

                                        <div
                                            className={`${rootStyle["card-body"]} ${rootStyle["text-center"]}`}
                                        >
                                            <CircularProgress
                                                style={{ position: "absolute" }}
                                                size={25}
                                                thickness={2}
                                            />
                                        </div>
                                        :

                                        <Table columns={cols} data={this.state.clients || []} />
                                    }

                                </div> */}
                            </div>
                        </div>
                    </div>
                </Paper>
            </>
        );
    }
}

export default withStyles(styles)(HomeClients)
