import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'react-admin';
import AuthUtils from './AuthUtils';
import { mapResource, mapResourceArray, getResourcePath } from './types';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const doApiRequest = async (method, path, body) => {
    // for /tokens resource we scan against users' Cognito username
    // TODO: get Cognito identity automatically in API Gateway via provided Authorization header
    if (method === 'GET' && path.indexOf('tokens') > -1) {
        const userAttributes = await AuthUtils.getUserAttributes();
        path += '&sub=' + userAttributes['cognito:username'];
    }
    const token = await AuthUtils.getToken();
    const options = {
        method,
        body: JSON.stringify(body),
        headers: new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        })
    };
    return fetch(`${apiUrl}${path}`, options)
        .then(response => response.json());
}

const objectResult = (resource, result, type = null) => {
    return {
        data: mapResource(resource, result, type),
    }
}

const arrayResult = (resource, result) => {
    return {
        data: mapResourceArray(resource, result.data),
        total: result.data.length,
        nextKey: result.nextKey,
    }
}

const getAll = async (url) => {
    let data = [];
    let nextKey = "";
    while (true) {
        const res = await doApiRequest("GET", url + "&limit=1000" + nextKey);
        data.push(...res.data);
        if (!res.nextKey || res.data.length === 0) {
            break;
        } else {
            nextKey = `&startKey=${res.nextKey}`;
        }
    }
    if (data.length === 0) {
        throw new Error("Not generating CSV for empty result");
    }
    return {data};
}

const getMoreResults = async (limitPerPage, total, path, filter, startKeyQuery, extraParams, sort) => {
    let limitValue = limitPerPage;
    limitValue-= total;
    const limitParam = `&limit=${(limitValue)}`;

    return await doApiRequest("GET", `${path}?${filter}${startKeyQuery}${extraParams}${sort}${limitParam}`);
};

// transform dates of event-defs to timestamp, because in DynamoDB dates are stored as numbers, presenting the timestamp
const transformDates = (data) => {
    data.startDate = new Date(data.startDate).getTime();
    data.endDate = new Date(data.endDate).getTime();

    if (data.deviantOn) {
        data.deviantOn = new Date(data.deviantOn).getTime();
    }

    if (data.deviantOff) {
        data.deviantOff = new Date(data.deviantOff).getTime();
    }

    return data;
};

/**
 * Maps react-admin queries to REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export const createDataProvider = () => {

    // sharedStartKeysCache keeps the start key for every page
    // because the data provider is shared between all resource, its also mapped per Resource
    // Example structure after filled:
    // {
    //   "user-stats": [null, "startkey for page 1", "startkey for page 2"],
    //   "event-defs": [null, "startkey for page 1", "startkey for page 2", "startkey for page 3"],
    // }
    let sharedStartKeysCache = {};

    // The start key cache is reset if filters have changed
    // So we keep the hash of the filters on every request
    let filterHash = "";

    return async (type, resource, params) => {
        const path = getResourcePath(resource);
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                let extraParams = "";
                if (type === GET_MANY_REFERENCE) {
                    extraParams = `&${params.target}=${params.id}`;
                }
                if (!sharedStartKeysCache[resource]) {
                    sharedStartKeysCache[resource] = []
                }

                // Reset cache if filters have changed
                const newFiltersHash = JSON.stringify(params.filter);
                if (newFiltersHash !== filterHash) {
                    sharedStartKeysCache[resource] = [];
                }
                filterHash = newFiltersHash;
                let startKeys = sharedStartKeysCache[resource];

                // Filters
                if (resource === 'event-logs' && Object.keys(params.filter).length === 0) {
                    return {
                        data: [],
                        total: 0
                    }
                }

                let filterParts = [];
                for (const key in params.filter) {
                    let value = params.filter[key];
                    if (key.indexOf("_to") > 0) {
                        value = new Date(value);
                        value.setHours(23);
                        value.setMinutes(59);
                        value.setSeconds(59);
                        value.setMilliseconds(999);
                        value = value.getTime();
                    }
                    if (key.indexOf("_from") > 0) {
                        value = new Date(value);
                        value.setHours(0);
                        value.setMinutes(0);
                        value.setSeconds(0);
                        value.setMilliseconds(0);
                        value = value.getTime();
                    }

                    if(resource === 'event-logs' && key === 'userid' && value === 'id'){
                        return {
                            data: [],
                            total: 0
                        }
                    }

                    filterParts.push(`${key}=${value}`);
                }
                const filter = filterParts.join("&");

                // Sort
                let sort = "";
                if (params.sort.field) {
                  sort = `&order=${params.sort.field}.${params.sort.order.toLowerCase()}`;
                }

                // Paging
                let {page, perPage} = params.pagination;
                const limit = `&limit=${params.pagination.perPage || 10}`;

                // CSV Requested
                if (perPage > 100) {
                    return arrayResult(
                        resource,
                        await getAll(`${path}?${filter}${extraParams}${sort}`),
                    );
                }

                let startKey = null;
                if (startKeys[page-1] && startKeys[page-1].nextKey) {
                    startKey = startKeys[page-1].nextKey;
                } else if (page > 1 && startKeys.length === 0) {
                    // The user opened link with page > 1, instead of goint to it by clicking next button
                    // in this case we redirect to the start of the list
                    window.location = window.location.href.split("?")[0];
                    window.location.reload();
                }
                const startKeyQuery = startKey !== null ? `&startKey=${startKey}` : "";

                // Do request
                const raResult = arrayResult(
                    resource,
                    await doApiRequest("GET", `${path}?${filter}${startKeyQuery}${extraParams}${sort}${limit}`)
                );

                let limitPerPage = params.pagination.perPage || 10;
                if ((raResult.data.length < limitPerPage) && raResult.nextKey) {
                    let tempArray = {
                        data: [],
                        nextKey: null,
                        total: 0,
                    };
                    // use nextKey of the previous page(stored in startKeys) to see the next page
                    let nextKey = page > 1 ? startKey : null;
                    let total = 0;
                    for (let i = 0; i < 5; i++) {
                        const startKey = nextKey !== null ? `&startKey=${nextKey}` : "";

                        let tempResult = await getMoreResults(limitPerPage, total, path, filter, startKey, extraParams, sort);

                        tempArray.data = tempArray.data.concat(tempResult.data);
                        tempArray.nextKey = tempResult.nextKey;

                        if (tempArray.data.length >= limitPerPage || !tempArray.nextKey) {
                            break;
                        }

                        nextKey = tempArray.nextKey;
                        total = tempArray.data.length;
                    }

                    let finalRecordsArray = arrayResult(resource, tempArray);

                    if (finalRecordsArray.nextKey) {
                        finalRecordsArray.total = finalRecordsArray.data.length * page + 1;
                    }

                    // Add to cache and return
                    startKeys[page] = finalRecordsArray;

                    return finalRecordsArray;
                }

                if (raResult.nextKey) {
                    raResult.total = raResult.data.length * page + 1;
                }

                // Add to cache and return
                startKeys[page] = raResult;

                return raResult;
            case GET_ONE:
                return objectResult(resource, await doApiRequest("GET", `${path}/${params.id}`), type);
            case CREATE:
                if (resource === "event-defs") {
                    // prepare parameters of the request
                    params.data = transformDates(params.data);
                }

                // make the request to the API
                const data = await doApiRequest("POST", `${path}`, params.data)
                    .then(entity => {
                      const mappedEntity = mapResource(resource, entity);
                      return {data: mappedEntity, id: mappedEntity.id};
                    });

                // check for errors
                if (data.data.message) {
                  throw new Error(data.data.message);
                }

                // form the response and return it
                return objectResult(resource, data);
            case UPDATE:
                if (resource === "event-defs") {
                    // prepare parameters of the request
                    params.data = transformDates(params.data);
                }

                // make the request to the API
                const updatedData = await doApiRequest("PUT", `${path}/${params.id}`, params.data)
                    .then(entity => {
                      const mappedEntity = mapResource(resource, entity);
                      return {data: mappedEntity, id: mappedEntity.id};
                    });

                // check for errors
                if (updatedData.data.message) {
                    throw new Error(updatedData.data.message);
                }

                // form the response and return it
                return objectResult(resource, updatedData);
            case DELETE:
                return objectResult(resource, await doApiRequest("DELETE", `${path}?id=${params.id}`));
            case GET_MANY:
                var result = objectResult(resource, await doApiRequest("GET", `${path}?${JSON.stringify(params.ids)}`));
                var mapped = [];
                result.data.data.forEach(function(obj) {
                  mapped.push(mapResource(resource, obj));
                });
                return {
                  data: mapped
                };
            default:
                throw new Error(`Unsupported Data Provider request type ${type}`);
        }
    }
};
