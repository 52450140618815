import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import AuthUtils from '../api/AuthUtils';
import { alertDialog } from "./helpers";
import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';

import onClickOutside from 'react-onclickoutside'

class ForgottenPasswordStep2 extends React.Component {
    state = {
        open: this.props.open,
        cognito_user: this.props.cognito_user,
        code_delivery_details: this.props.code_delivery_details,
        error_message: '',
        code: '',
        password: '',
        repeat_password: '',
        is_loading: false
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    handleClickOutside = () => {
        this.props.closeAll();
    }

    componentDidUpdate = () => {

    };

    handleSubmit = async (e) => {
        var me = this;
        e.preventDefault();
        if (this.state.code.length === 0 || this.state.password.length === 0 || this.state.repeat_password.length === 0) {
            this.setState({error_message: "All fields are required"});
            return;
        }
        if (this.state.password !== this.state.repeat_password) {
            this.setState({error_message: "Repeat password does not match"});
            return;
        }

        this.state.cognito_user.confirmPassword(this.state.code, this.state.password, {
            onSuccess: function() {
                alertDialog('success', 'Password has been reset');
                AuthUtils.redirectHome();
            },
            onFailure: function(err) {
                me.setState({error_message: err.message});
            }
        })
    };

    render() {
        console.log(this.props)
        return (
            <div>
                <DialogContent>

                    <div className={`${rootStyle['container-fluid']} ${commonStyle['modal-container']}`}>
                        <div className={`${rootStyle['row']} `}>
                            <div className={`${rootStyle['col-md-12']}`}>
                                <p className={commonStyle['p-n-m']}>
                                    <Close onClick={this.props.close} />
                                </p>
                                <div className={`${rootStyle['card-group']}`}>
                                    <div className={`${rootStyle['card']} ${rootStyle['p-4']} ${commonStyle['card']}`}>
                                        <div className={`${rootStyle['card-body']} ${commonStyle['card-body-np']}`}>
                                            <h2>We have sent a code to reset password</h2>
                                            <form onSubmit={e => this.handleSubmit(e)}>
                                                <div className={`${rootStyle['input-group']} ${rootStyle['mb-4']}`}>
                                                    <div className={`${rootStyle['input-group-prepend']}`}>

                                                    </div>
                                                    <input className={`${rootStyle['form-control']}`} name="code"
                                                           placeholder="Code"
                                                           label="Code"
                                                           type="text"
                                                           value={this.state.code}
                                                           onChange={this.handleChange('code')}
                                                           disabled={this.state.is_loading}
                                                           required
                                                    />
                                                </div>

                                                <div className={`${rootStyle['input-group']} ${rootStyle['mb-4']}`}>
                                                    <div className={`${rootStyle['input-group-prepend']}`}>

                                                    </div>
                                                    <input className={`${rootStyle['form-control']}`} name="password"
                                                           placeholder="Password"
                                                           label="Password"
                                                           type="password"
                                                           value={this.state.password}
                                                           onChange={this.handleChange('password')}
                                                           disabled={this.state.is_loading}
                                                           required
                                                    />
                                                </div>

                                                <div className={`${rootStyle['input-group']} ${rootStyle['mb-4']}`}>

                                                    <input className={`${rootStyle['form-control']}`} name="repeat_password"
                                                           label="Password"
                                                           placeholder="Repeat Password"
                                                           type="password"
                                                           value={this.state.repeat_password}
                                                           onChange={this.handleChange('repeat_password')}
                                                           disabled={this.state.is_loading}
                                                           required
                                                    />
                                                </div>

                                                <div className={commonStyle['input-error']}>
                                                    {this.state.error_message}
                                                </div>


                                                <div className={`${rootStyle['row']}`}>
                                                    <div className={`${rootStyle['col-12']}`}>
                                                        <button variant="raised"
                                                                type="submit"
                                                                color="primary"
                                                                className={`${rootStyle['btn']} ${rootStyle['btn-primary']} ${rootStyle['px-4']}`}
                                                        >

                                                            Change password
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
        );
    }
}

export default onClickOutside(ForgottenPasswordStep2);

