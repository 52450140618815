import React from 'react';
import LoadingImage from '../images/Rolling-0.8s-100px.gif';

const LoadingIndicator = props => <div styles={styles.wrapper}>
    <img src={LoadingImage} alt="Loading indicator" style={styles.image}/>
</div>;

const styles = {
	wrapper: {
		width: '100%',
		height: '100%',
		textAlign: 'center',
		position: "relative,"
	},
	image: {
		position: "absolute",
		margin: "auto",
		top: "20%",
		right: 0,
		left: 0,
	}
}


export default LoadingIndicator;
