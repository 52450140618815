import React from 'react';
import Paper from '@material-ui/core/Paper';
import classnames from 'classnames';
import moment from 'moment-timezone';

import CardHeader from './CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';

import {doRequest} from "./helpers";

import rootStyle from '../styles/style.module.css';
import commonStyle from '../styles/common.module.css';
import tableStyle from '../styles/table.module.css';
import ErrorMessage from "./ErrorMessage";

moment.tz.setDefault('UTC');

const styles = {
    root: {
        marginBottom: 16
    },
    content: {
        backgroundColor: '#43a047',
    }
};

class HomeRetriggerLoading extends React.Component {
    constructor(props) {
        super(props);

        var date_start = new Date();
        date_start.setDate(date_start.getDate() - 7);
        date_start = moment(date_start).format('YYYY-MM-DD');
        var date_end = new Date();
        date_end = moment(date_end).format('YYYY-MM-DD');

        this.state = {
            clientId: '',
            serviceId: '',
            clients: [],
            services: [],
            result: [],
            loadingClients: false,
            loadingServices: false,
            loadingResult: false,
            dateStart: date_start,
            dateEnd: date_end,
            error: '',
        };

        // this.submitQuery = this.submitQuery.bind(this);
    }

    componentDidMount() {
        // this.getClientOptions();
        this.getServiceOptions();
    }

    async getClientOptions() {
        this.setState({loadingClients: true});
        let res = await doRequest('GET', '/admin_list_clients', null)
        this.setState({loadingClients: false});

        if (res.success) {
            this.setState({ clients: res.data });
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    async getServiceOptions() {
        this.setState({loadingServices: true});
        let res = await doRequest('GET', '/admin_list_services', null)
        this.setState({loadingServices: false});

        if (res.success) {
            this.setState({ services: res.data });
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    async submitQuery() {
        this.setState({error: '', result: []});

        var has_error = false;
        if (this.state.clientId.length === 0) {
            has_error = true;
        }
        if (this.state.serviceId.length === 0) {
            has_error = true;
        }
        if (this.state.dateStart.length === 0) {
            has_error = true;
        }
        if (this.state.dateEnd.length === 0) {
            has_error = true;
        }
        if (has_error) {
            this.setState({error: 'Fill all fields'});
            return;
        }

        this.setState({loadingResult: true});
        let res = await doRequest('POST', '/admin_retrigger_loading_service', {
            client_id: this.state.clientId,
            service_id: this.state.serviceId,
            date_start: this.state.dateStart,
            date_end: this.state.dateEnd
        });
        this.setState({loadingResult: false});

        if (res.success) {
            if (res.data.length === 0) {
                alert('No data found');
                return;
            }

            this.setState({ result: res.data});
        } else {
            console.log(res.message);
            this.setState({ error: res.message });
        }
    }

    render() {
        return (
            <Paper style={{ padding: 5 }} className={commonStyle['default']}>
                <div className={rootStyle['container-fluid']}>
                    <div className={rootStyle.card}>
                        <CardHeader
                            title='Trigger Loading Service'
                            description=""
                        />
                        <div className={rootStyle['card-body']}>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="client-select" className={tableStyle['form-left-label']}>Client ID:</label>
                                    {/* <select
                                        className={rootStyle["custom-select"]}
                                        onChange={(e) => {
                                            this.setState({ clientId: e.target.value });
                                        }}
                                        id="client-select"
                                        value={this.state.clientId}>
                                        <option value=''>Select Client</option>
                                        {this.state.clients && this.state.clients.length > 0 ?
                                            this.state.clients.map((o, i) => {
                                                return (
                                                    <option key={i} value={o['client_id']}>{o['client_name']}</option>
                                                );
                                            })
                                            : null}
                                    </select> */}
                                    <input type="text"
                                           className={`${rootStyle["form-control"]}`}
                                           value={this.state.clientId}
                                           onChange={(e) => {
                                               this.setState({ clientId: e.target.value });
                                           }}
                                    />
                                </div>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="service-select" className={tableStyle['form-left-label']}>And service:</label>
                                    <select
                                        className={rootStyle["custom-select"]}
                                        onChange={(e) => this.setState({ serviceId: e.target.value })}
                                        id="service-select"
                                        value={this.state.serviceID}>
                                        <option value=''>Select Service</option>
                                        {this.state.services && this.state.services.length > 0 ?
                                            this.state.services.map((o, i) => {
                                                return (
                                                    <option key={i} value={o['service_id']}>{o['service_name']}</option>
                                                );
                                            })
                                            : null}
                                    </select>
                                </div>
                            </div>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="date-start-input" className={tableStyle['form-left-label']}>Time Start:</label>
                                    <input type="date"
                                           className={`${rootStyle["form-control"]}`}
                                           value={this.state.dateStart}
                                           onChange={(e) => {
                                               this.setState({ dateStart: e.target.value });
                                           }}
                                           />
                                </div>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label htmlFor="date-end-input" className={tableStyle['form-left-label']}>Time End:</label>
                                    <input type="date"
                                           className={`${rootStyle["form-control"]}`}
                                           value={this.state.dateEnd}
                                           onChange={(e) => {
                                               this.setState({ dateEnd: e.target.value });
                                           }}
                                    />
                                </div>
                            </div>
                            <div className={rootStyle['row']}>
                                <div className={`${rootStyle['form-group']} ${rootStyle['col-sm-2']}`}>
                                    <label className={tableStyle['form-left-label']}>&nbsp;</label>
                                    <button
                                        type="submit"
                                        className={classnames(rootStyle['btn'], rootStyle['btn-primary'], tableStyle['flex-button'])}
                                        onClick={() => {this.submitQuery('replace')}}
                                    >
                                        {this.state.loadingResult && <CircularProgress style={{ position: 'absolute' }} size={25} thickness={2} />}
                                        Submit
                                    </button>
                                </div>
                            </div>

                            <ErrorMessage msg={this.state.error}/>

                            <div className={rootStyle['row']}>
                                <div className={tableStyle['table-wrapper']}>
                                    {this.state.result && this.state.result.length > 0 && <React.Fragment>
                                        <span>These files has been sent to the loading service:</span>
                                        <table>
                                            <thead className={`${tableStyle["table-head"]}`}>
                                            <tr>
                                                <th>Bucket</th>
                                                <th>Key</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.result.map((item, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{item['bucket']}</td>
                                                        <td>{item['filename']}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </React.Fragment>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(HomeRetriggerLoading)